import Fetcher from "../libs/Fetcher"

const url = '/payment_services';

const STATUS_OK = 200;
const CREATED = 201;

export const indexPaymentServices = async (props) => {
    const {
        headers,
        page,
        pageSize,
        checker_id,
        agent_technical_id,
        status,
        phone,
        start_date,
        end_date
    } = props;
    let response = { data: [], totalItems: 0 };
    try {
        let params = {};
        if (page && pageSize)
            params = { page, pageSize }
        if (checker_id !== '-1') params.checker_id = checker_id;
        if (agent_technical_id !== '-1') params.agent_technical_id = agent_technical_id;
        if (status !== '-1') params.status = status;
        if (phone) params.phone = phone;
        if (start_date) params.start_date = start_date;
        if (end_date) params.end_date = end_date;

        let fetch = await Fetcher({
            method: 'GET',
            url: `${url}`,
            headers,
            params
        });
        console.log(`Index ${url}`, fetch);
        if (fetch.status === STATUS_OK)
            response = fetch.data;
    } catch (error) {
        response = { data: [], totalItems: 0 };
    } finally {
        return response;
    }
}

export const storePaymentServices = async ({ headers, data }) => {
    let response = false;
    try {
        let fetch = await Fetcher({
            method: 'POST',
            url: `${url}`,
            data,
            headers
        });
        console.log(`Store ${url}`, fetch);
        if (fetch.status === CREATED)
            response = fetch.data.data
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}

export const showPaymentServices = async ({ headers, payment_service_id }) => {
    let response = false;
    try {
        let fetch = await Fetcher({
            method: 'GET',
            url: `${url}/${payment_service_id}`,
            headers
        });
        console.log(`Show ${url}`, fetch);
        if (fetch.status === STATUS_OK)
            response = fetch.data.data
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}

export const updatePaymentServices = async ({ headers, payment_service_id, data }) => {
    let response = false;
    try {
        let fetch = await Fetcher({
            method: 'PUT',
            url: `${url}/${payment_service_id}`,
            data,
            headers
        });
        console.log(`Update ${url}`, fetch);
        if (fetch.status === STATUS_OK)
            response = fetch.data.data
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}