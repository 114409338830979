import { Box, Input, useToast } from "@chakra-ui/react";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { Fragment, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { useSelector } from "react-redux";
import { updateUsers } from "../../api/User";
import setToast from "../../libs/Toast";
import ButtonIcon from "../layouts/ButtonIcon";
import FormIcon from "../layouts/FormIcon"

const General = () => {
    const login = useSelector(state => state.login);
    const user = login.user;
    const headers = { Authorization: `Bearer ${login.token}` };
    const toast = useToast();

    const [data, setData] = useState({});               // Nuevos datos capturados
    const [active, setActive] = useState(false);        // Habilitar el botón para guardar cambios

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
        setActive(true);
    }

    const updateData = async () => {
        setActive(false);
        let response = await updateUsers({ headers, data, user_id: user.id });
        if (response) {
            setToast({
                toast, title: '¡Operación exitosa!', status: 'success',
                description: 'Información actualizada con éxito.'
            });
        } else {
            setToast({
                toast, title: '¡Operación fallida!', status: 'warning',
                description: 'Ha ocurrido un error al tratar de actualizar la información proporcionada.'
            });
        }
    }
    return (
        <Fragment>
            <Box p={5} shadow='md' borderWidth='1px' className='mt-3'>
                <Container fluid>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6} xl={4} className="mt-3">
                            <FormIcon
                                title='Correo electróncio'
                                name='email'
                                defaultValue={user?.email}
                                value={data?.email}
                                disabled
                            />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={4} className="mt-3">
                            <FormIcon
                                title='Nombre(s)'
                                name='first_name'
                                defaultValue={user?.first_name}
                                value={data?.first_name}
                                handleChange={handleChange}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={4} className="mt-3">
                            <FormIcon
                                title='Apellido(s)'
                                name='last_name'
                                defaultValue={user?.last_name}
                                value={data?.last_name}
                                handleChange={handleChange}
                            />
                        </Col>
                    </Row>
                </Container>
                <Row className="mt-5 mb-3">
                    <Col md={{ span: 2, offset: 10 }}>
                        <ButtonIcon
                            name='Guardar cambios'
                            icon={faSave}
                            variant='outline-success'
                            size={'md'}
                            disabled={!active}
                            onClick={updateData}
                        />
                    </Col>
                </Row>
            </Box>
        </Fragment>
    )
}

export default General