import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, Heading, List, ListIcon, ListItem, Text, useToast } from '@chakra-ui/react'
import { faSave } from '@fortawesome/free-solid-svg-icons'
import { MdDangerous, MdCheckCircle } from "react-icons/md"
import { useState } from 'react'
import { useEffect } from 'react'
import { Fragment } from 'react'
import { Col, Row } from 'react-bootstrap'
import ButtonIcon from '../layouts/ButtonIcon'
import FormIcon from '../layouts/FormIcon'
import { showLog } from '../../libs/Logs'
import { updateUsers } from '../../api/User'
import { useSelector } from 'react-redux'
import setToast from '../../libs/Toast'

const ChangePassword = () => {
    const login = useSelector(state => state.login);
    const user = login.user;
    const headers = { Authorization: `Bearer ${login.token}` };
    const toast = useToast();

    const [active, setActive] = useState(false);
    const [data, setData] = useState(false);
    const [notify, setNotify] = useState([
        { type: '1', message: 'Debe tener al menos 8 caracteres', status: false },
        { type: '2', message: 'Deben coincidir', status: false }
    ]);

    useEffect(() => {
        let band = false;
        if (data.new_password === data.confirm_password && data?.new_password?.length >= 8) {
            setNotify(notify.map((item) => {
                return { ...item, status: true }
            }));
            band = true;
            setActive(true);
        } else {
            setActive(false);
        }
        if (!band) {
            if (data?.new_password?.length >= 8)
                changeNotify('1', true);
            else
                changeNotify('1', false);

            if (data.new_password === data.confirm_password)
                changeNotify('2', true);
            else
                changeNotify('2', false);
        }
        // showLog({ message: 'Nots: ', obj: notify });
    }, [data]);

    const changeNotify = (type, status) => {
        setNotify(notify => notify.map((item) => {
            if (item.type === type)
                return { ...item, status }
            return item;
        }));
    }
    const handleChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    }

    const updatePassword = async () => {
        setActive(false);
        let response = await updateUsers({
            headers,
            data: {
                password: data.new_password
            },
            user_id: user.id
        });
        if (response) {
            setToast({
                toast, title: '¡Operación exitosa!', status: 'success',
                description: 'Contraseña actualizada con éxito.'
            });
        } else {
            setToast({
                toast, title: '¡Operación fallida!', status: 'warning',
                description: 'Ha ocurrido un error al tratar de actualizar la contraseña proporcionada.'
            });
        }
    }
    return (
        <Fragment>
            <Box p={5} shadow='md' borderWidth='1px' className='mt-3'>
                <Heading className="mt-3" size={'md'}>Cambiar contraseña</Heading>
                <Text>
                    Te recomendamos que actualices periódicamente tu contraseña para evitar el acceso no autorizado a tu cuenta.
                </Text>
                <Row className="mt-5 mb-5">
                    {/* <FormIcon title='Contraseña actual' /> */}
                    <Col xs={12} sm={12} md={6} lg={6} xl={4} className="mt-3">
                        <FormIcon
                            type='password'
                            title='Nueva contraseña'
                            name='new_password'
                            value={data?.new_password}
                            handleChange={handleChange}
                        />
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={6} xl={4} className="mt-3">
                        <FormIcon
                            type='password'
                            title='Confirmar contraseña'
                            name='confirm_password'
                            value={data?.confirm_password}
                            handleChange={handleChange}
                        />
                    </Col>
                </Row>
                {
                    data?.new_password?.length > 0 &&
                    <List spacing={3}>
                        {
                            notify.map((item) =>
                                <ListItem>
                                    <ListIcon as={item.status ? MdCheckCircle : MdDangerous} color={item.status ? 'green' : 'red'} />
                                    {item.message}
                                </ListItem>
                            )
                        }
                    </List>
                }
                <Row className="mt-5 mb-3">
                    <Col md={{ span: 3, offset: 9 }} style={{ textAlign: 'right' }}>
                        <ButtonIcon
                            name='Cambiar contraseña'
                            icon={faSave}
                            variant='outline-success'
                            size={'md'}
                            disabled={!active}
                            onClick={updatePassword}
                        />
                    </Col>
                </Row>
            </Box>
        </Fragment>
    )
}

export default ChangePassword