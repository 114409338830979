import { Box, Heading, Text, useToast } from '@chakra-ui/react'
import { faToggleOn } from '@fortawesome/free-solid-svg-icons'
import { Fragment, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { updateUsers } from '../../api/User'
import setToast from '../../libs/Toast'
import { CLOSE_SESSION } from '../../redux/actions/main'
import ButtonIcon from '../layouts/ButtonIcon'
import TwoFactorModal from './TwoFactorModal'

const TwoFactor = () => {
    const login = useSelector(state => state.login);
    const user = login.user;
    const headers = { Authorization: `Bearer ${login.token}` };
    const toast = useToast();
    const dispatch = useDispatch();

    const [showModal, setShowModal] = useState(false);
    const [type, setType] = useState('');

    const openOrClose = () => setShowModal(!showModal);

    const enableTwoFactor = () => {
        setType('enable');
        openOrClose();
    }

    const disableTwoFactor = () => {
        setType('disable');
        openOrClose();
    }

    const confirm = async () => {
        let response = await updateUsers({
            headers,
            user_id: user.id,
            data: {
                has_two_factor: type === 'enable' ? 'true' : 'false'
            }
        });
        if (response) {
            setToast({
                toast, title: '¡Operación exitosa!', status: 'success',
                description: `La autenticación en dos pasos ha sido ${type === 'enable' ? 'habilitada' : 'deshabilitada'}. Le sesión finalizará para actualizar la información.`
            });
            dispatch({ type: CLOSE_SESSION });
        } else {
            setToast({
                toast, title: '¡Operación fallida!', status: 'warning',
                description: 'Ha ocurrido un error al tratar de ejecutar esta operación.'
            });
        }
    }

    return (
        <Fragment>
            <Box p={5} shadow='md' borderWidth='1px' className='mt-3'>
                <Heading className="mt-3" size={'md'}>Autenticación en dos pasos</Heading>
                <Box p={5} shadow='md' borderWidth='1px' className='mt-3 mb-3'>
                    <Row className='mb-3'>
                    <Col sm={12} md={6} lg={8} xl={10} className='mt-3'>
                            <Text>
                                {
                                    user.has_two_factor ? 'La verificación en dos pasos se encuentra ACTIVA' :
                                        'Protege tu cuenta frente a accesos no autorizados al solicitar un código seguro cuando inicias sesión.'
                                }

                            </Text>
                        </Col>
                        <Col sm={12} md={6} lg={4} xl={2} className='mt-3' style={{ textAlign: 'right' }}>
                            {
                                user.has_two_factor ? <ButtonIcon name='Deshabilitar' variant='outline-danger' icon={faToggleOn} onClick={disableTwoFactor} />
                                    : <ButtonIcon name='Habilitar' variant='outline-success' icon={faToggleOn} onClick={enableTwoFactor} />
                            }
                        </Col>
                    </Row>
                </Box>
            </Box>
            {/* Modal para confirmación */}
            {
                showModal && <TwoFactorModal
                    onClose={openOrClose}
                    type={type}
                    confirm={confirm}
                />
            }
        </Fragment>
    )
}

export default TwoFactor