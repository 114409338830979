import { Input, InputGroup, InputRightElement } from '@chakra-ui/react'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Row } from 'react-bootstrap'

const Search = (props) => {
    const { name, setName, getPayments } = props;
    const handleChange = (e) => setName(e.target.value);
    return (
        <Row className='mb-3'>
            <InputGroup size='lg'>
                <Input
                    pr='4.5rem'
                    type='text'
                    value={name}
                    onChange={handleChange}
                    placeholder='Digite el nombre del servicio'
                />
                <InputRightElement width='4.5rem'>
                    <FontAwesomeIcon icon={faSearch} color='blue' onClick={getPayments}/>
                </InputRightElement>
            </InputGroup>
        </Row>
    )
}

export default Search