import { Alert, AlertDescription, AlertIcon, AlertTitle, Box } from "@chakra-ui/react"


const Building = () => {
    return (
        <Alert status='info'>
            <AlertIcon />
            <Box>
                <AlertTitle>¡Módulo en construcción!</AlertTitle>
                <AlertDescription>
                    Lamentamos los inconvenientes que esto pueda causar, estamos trabajando para brindarle una mejor experiencia de usuario.
                </AlertDescription>
            </Box>
        </Alert>
    )
}

export default Building