import Fetcher from "../libs/Fetcher"

const STATUS_OK = 200;
const CREATED = 201;

export const indexBalanceRequests = async (props) => {
    const {
        headers,
        page,
        pageSize,
        agent_technical_id,
        status,
        balance_id,
        start_date,
        end_date
    } = props;

    let response = { data: [], totalItems: 0 };
    try {
        let params = {};
        if (page) params.page = page;
        if (pageSize) params.pageSize = pageSize;
        if (agent_technical_id !== '-1') params.agent_technical_id = agent_technical_id;
        if (status !== '-1') params.status = status;
        if (balance_id) params.balance_id = balance_id;
        if (start_date) params.start_date = start_date;
        if (end_date) params.end_date = end_date;

        let fetch = await Fetcher({
            method: 'GET',
            url: '/balance_requests',
            headers,
            params
        });
        console.log("Index balance requests: ", fetch);
        if (fetch.status === STATUS_OK)
            response = fetch.data;
    } catch (error) {
        response = { data: [], totalItems: 0 };
    } finally {
        return response;
    }
}

export const storeBalanceRequests = async ({ headers, data }) => {
    let response = false;
    try {
        let fetch = await Fetcher({
            method: 'POST',
            url: `/balance_requests`,
            data,
            headers
        });
        console.log("create balance requests: ", fetch);
        if (fetch.status === CREATED)
            response = fetch.data.data
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}

export const updateBalanceRequests = async ({ headers, data, balance_request_id }) => {
    let response = false;
    try {
        let fetch = await Fetcher({
            method: 'PUT',
            url: `/balance_requests/${balance_request_id}`,
            data,
            headers
        });
        console.log("update balance requests: ", fetch);
        if (fetch.status === STATUS_OK)
            response = fetch.data.data
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}