import React, { Fragment } from 'react'
import Balance from './Balance'

const TechnicalAgent = () => {
    return (
        <Fragment>
            <Balance />
        </Fragment>
    )
}

export default TechnicalAgent