import { Box, Heading, Image, Text } from '@chakra-ui/react';
import React, { Fragment } from 'react'
import SkeletonStack from '../../layouts/Skeletons/Stack';

const Item = (props) => {
    const { logo, assigned, spended, remaining, loading } = props;
    const options = { style: 'currency', currency: 'USD' };
    const numberFormat = new Intl.NumberFormat('en-US', options);

    return (
        <Box maxW='md' minH={80} borderWidth='1px' borderRadius='lg' overflow='hidden' style={{ backgroundColor: '#FFF' }}>
            <Image src={logo} />
            <Box p='6' alignContent={'flex-end'}>
                {
                    loading ? <SkeletonStack rows={6} /> : <Fragment>
                        <Text>Saldo asignado:</Text>
                        <Heading>{numberFormat.format(assigned)}</Heading>
                        <Text>Saldo gastado:</Text>
                        <Heading>{numberFormat.format(spended)}</Heading>
                        <Text>Saldo restante:</Text>
                        <Heading>{numberFormat.format(remaining)}</Heading>
                    </Fragment>
                }
            </Box>
        </Box>
    )
}

export default Item