import { faEye } from '@fortawesome/free-solid-svg-icons';
import { Col, Row } from 'antd';
import { Fragment, useEffect, useState } from 'react'
import { indexRecharges } from '../../../api/Recharges';
import PaginationComp from '../../layouts/Pagination';
import TableComp from '../../layouts/TableComp'
import PaymentFilters from './PaymentFilters';
import RechargeDetails from './RechargeDetails';

const RechargesTable = (props) => {
    const { headers } = props;

    const [recharges, setRecharges] = useState([]);
    const [rechargeSel, setRechargeSel] = useState({});
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [loading, setLoading] = useState(true);
    const [modalDet, setModalDet] = useState(false);
    const [filters, setFilters] = useState({});

    useEffect(() => {
        getRecharges();
    }, [page, pageSize]);

    const getRecharges = async () => {
        setLoading(true);
        let response = await indexRecharges({ headers, page, pageSize, ...filters });
        setRecharges(response.data);
        setTotalItems(response.totalItems);
        setLoading(false);
    }

    const changePage = (page, pageSize) => {
        setPage(page);
        setPageSize(pageSize);
    }

    const openOrCloseDet = () => setModalDet(!modalDet);

    const viewDetails = (item) => {
        setRechargeSel(item);
        openOrCloseDet();
    }

    const searchByFilters = () => {
        setPage(1);
        getRecharges();
    }
    return (
        <Fragment>
            <Row justify='end' align='middle' className='mb-2'>
                <Col flex={0}>
                    <PaymentFilters
                        filters={filters}
                        setFilters={setFilters}
                        searchByFilters={searchByFilters}
                        headers={headers}
                    />
                </Col>
            </Row>
            <TableComp
                headers={['#', 'Folio', 'Autcarrier', 'Cajero', 'Producto', 'Teléfono', 'Cantidad', 'Estado', 'Creado en', 'Acciones']}
                keys={['#', 'folio', 'autcarrier', 'checker', 'product', 'phone', 'amount', 'isDone', 'created_at']}
                body={recharges}
                loading={loading}
                loadingItems={pageSize}
                page={page}
                actions={[
                    { icon: faEye, variant: 'outline-primary', tooltip: 'Ver detalles', handleClick: viewDetails }
                ]}
                currency={[{ index: 6 }]}
                badges={[
                    { index: 7, color: 'isDone_color' }
                ]}
            />
            <PaginationComp totalItems={totalItems} current={page} onChange={changePage} />
            {
                modalDet && <RechargeDetails
                    onClose={openOrCloseDet}
                    movement={rechargeSel}
                    headers={headers}
                />
            }
        </Fragment>
    )
}

export default RechargesTable