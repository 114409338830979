import { Result } from 'antd';
import { Container } from "react-bootstrap";
import { useHistory } from 'react-router';
import ButtonIcon from '../layouts/ButtonIcon';

const PermissionDenied = () => {
    const history = useHistory();

    const handleBack = () => {
        history.push('/');
    }

    return (
        <Container id='permission-denied-component'>
            <Result
                status="403"
                title='Acceso denegado [error 403]'
                subTitle='Lo sentimos, no estás autorizado para acceder a esta página.'
                extra={
                    <ButtonIcon
                        variant='outline-primary'
                        onClick={handleBack}
                        name='Regresar al inicio'
                    />
                }
            />
        </Container>
    );
}

export default PermissionDenied;