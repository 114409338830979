import { useDisclosure } from "@chakra-ui/hooks";
import { useRef, Fragment } from "react";
import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
} from "@chakra-ui/react";
import { faFilter, faSearch } from "@fortawesome/free-solid-svg-icons";
import PropTypes from 'prop-types';
import ButtonIcon from "./ButtonIcon";

const DrawerComp = (props) => {
    const { title, children, searchByFilters } = props;
    const { isOpen, onOpen, onClose } = useDisclosure();
    const btnRef = useRef();
    
    const handleClickSearch = () => {
        searchByFilters();
        onClose();
    }
    return (
        <Fragment>
            <ButtonIcon
                tooltip={'Filtrar resultados a través de búsquedas específicas'}
                name={'Filtrar búsqueda'}
                icon={faFilter}
                variant='outline-secondary'
                onClick={onOpen}
            />
            <Drawer
                isOpen={isOpen}
                placement="right"
                onClose={onClose}
                finalFocusRef={btnRef}
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader borderBottomWidth='1px'>{title}</DrawerHeader>
                    <DrawerBody>
                        {children}  {/* Filtros de búsqueda */}
                    </DrawerBody>
                    <DrawerFooter style={{ display: 'block' }}>
                        <div className="d-grid gap-2 mt-4">
                            <ButtonIcon
                                onClick={handleClickSearch}
                                size='lg'
                                name={'Buscar'}
                                icon={faSearch}
                                variant='outline-success'
                            />
                        </div>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>

        </Fragment >
    );
}

DrawerComp.propTypes = {
    title: PropTypes.string.isRequired,
    searchByFilters: PropTypes.func.isRequired
};

export default DrawerComp;