import { faBell, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Layout, Menu } from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { logout } from '../../api/Authenticate';
import { CLOSE_SESSION } from '../../redux/actions/main';
import Sidebar from './Sidebar';

const { Content, Footer } = Layout;

const MainLayout = (props) => {
    const { children } = props;
    const login = useSelector(state => state.login);
    const user = login.user;
    const headers = { Authorization: `Bearer ${login.token}` };
    const dispatch = useDispatch();
    const [marginLeft, setMarginLeft] = useState(200);

    const closeSession = async () => {
        logout({ headers });
        dispatch({ type: CLOSE_SESSION });
    }

    return (
        <Layout style={{ minHeight: '100vh' }}>
            {/* Barra de navegación lateral */}
            <Sidebar setMarginLeft={setMarginLeft} />
            <Layout className="site-layout" style={{ marginLeft, transition: 'all 0.2s' }}>
                <Menu theme="dark" mode="horizontal" style={{ justifyContent: 'flex-end' }}>
                    <SubMenu key="notifications" icon={<FontAwesomeIcon icon={faBell} />} title=" Notificaciones">
                        <Menu.Item key="setting:1">No hay notificaciones</Menu.Item>
                    </SubMenu>
                    <SubMenu key="user" icon={<FontAwesomeIcon icon={faUser} />} title={" " + user.email}>
                        <Menu.Item key="config"><Link to={'/config'} /> Configuración de perfil</Menu.Item>
                        <Menu.Item key="logout" onClick={closeSession}> Cerrar sesión</Menu.Item>
                    </SubMenu>
                </Menu>
                <Content style={{ margin: '0 16px' }}>
                    {/* Miga de pan (Navegación secundaria) */}
                    {/* <BreadcrumbComponent /> */}
                    <section className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                        {/* Swich de Route DOM (Donde pasan los componentes que son renderizados) */}
                        {children}
                    </section>
                </Content>
                <Footer style={{ textAlign: 'center' }}>FastPay By GRUPO CTI</Footer>
            </Layout>
        </Layout>
    )
}

export default MainLayout