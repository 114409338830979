
import './App.css';
import { Helmet } from 'react-helmet';
import { Fragment } from 'react';
import App from './components/App';
import { useSelector } from 'react-redux';
import Login from './components/Login/Index';

function IndexComponent() {

  const session = useSelector(state => state.login.session);
// const session = true;
  return (
    <Fragment>
      <Helmet>
        <title>FastPay</title>
      </Helmet>
      {
        session ? <App /> : <Login />
      }

    </Fragment>
  );
}

export default IndexComponent;
