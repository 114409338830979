import { Empty } from "antd";

const NoData = () => {

    return (
        <Empty
            description={
                <span>
                    No se encontraron registros
                </span>
            }
        />
    );
}

export default NoData;