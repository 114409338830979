import { useToast } from '@chakra-ui/react';
import { faAngleDoubleLeft, faSave } from '@fortawesome/free-solid-svg-icons';
import { Fragment, useState } from 'react';
import { Row } from 'react-bootstrap';
import { storeTechnicalAgents } from '../../../api/TechnicalAgents';
import setToast from '../../../libs/Toast';
import ButtonIcon from '../../layouts/ButtonIcon';
import FormIcon from '../../layouts/FormIcon';
import ModalComp from '../../layouts/Modal'

const Add = (props) => {
    const { onClose, headers, update } = props;
    const [data, setData] = useState({});
    const toast = useToast();

    const validate = () => {
        let response = true;
        return response;
    }
    const saveChecker = async () => {
        if (validate()) {
            let response = await storeTechnicalAgents({ headers, data });
            if (response) {
                update();
                setToast({
                    toast, title: '¡Operación exitosa!', status: 'success',
                    description: 'El agente técnico ha sido agregado con éxito.'
                });
                onClose();
            } else {
                setToast({
                    toast, title: '¡Operación fallida!', status: 'error',
                    description: 'Ha ocurrido un error al tratar de agregar al agente técnico.'
                });
            }
        }
    }
    const handleChange = (e) => {
        let { value, name } = e.target;
        setData({ ...data, [name]: value });
    }

    return (
        <ModalComp
            title='Agregar agente técnico'
            size='md'
            onClose={onClose}
            body={<Fragment>
                <Row className='mb-3'>
                    <FormIcon title='Nombre(s)' name='first_name' value={data?.first_name} handleChange={handleChange} />
                </Row>
                <Row className='mb-3'>
                    <FormIcon title='Apellido(s)' name='last_name' value={data?.last_name} handleChange={handleChange} />
                </Row>
                <Row className='mb-3'>
                    <FormIcon title='Correo electrónico' name='email' value={data?.email} handleChange={handleChange} />
                </Row>
                <Row className='mb-3'>
                    <FormIcon title='Contraseña' name='password' value={data?.password} type='password' handleChange={handleChange} />
                </Row>
                {/* <Row className='mb-3'>
                    <FormIcon title='Confirmar contraseña' name='passsword_confirm' value={data?.passsword_confirm} type='password' />
                </Row> */}
            </Fragment>}
            footer={<Fragment>
                <ButtonIcon name='Cerrar' icon={faAngleDoubleLeft} onClick={onClose} variant='outline-secondary' />
                <ButtonIcon name='Agregar' icon={faSave} onClick={saveChecker} variant='outline-success' />
            </Fragment>}
        />
    )
}

export default Add