import { Heading, useToast } from '@chakra-ui/react'
import { faBan, faCheckDouble, faFileDownload, faFilePdf, faHourglassHalf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { indexBalanceRequests } from '../../api/BalanceRequests';
import { showTechnicalAgents } from '../../api/TechnicalAgents';
import downloadFile from '../../libs/Download';
import FlexButton from '../layouts/FlexButton';
import PaginationComp from '../layouts/Pagination';
import TableComp from '../layouts/TableComp'
import Add from './Comprobantes/Add';

const Comprobantes = () => {
	const login = useSelector(state => state.login);
	const headers = { Authorization: `Bearer ${login.token}` };
	const agent_technical_id = login.user.id;
	const toast = useToast();
	const [deposits, setDeposits] = useState([]);
	const [loading, setLoading] = useState(false);
	const [modalAdd, setModalAdd] = useState(false);
	const [agentTechnical, setAgentTechnical] = useState(null);
	const [totalItems, setTotalItems] = useState(0);
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);

	useEffect(() => {
		getDetailAgentTechnical();
	}, []);

	useEffect(() => {
		getDeposits();
	}, [page, pageSize]);

	const getDeposits = async () => {
		setLoading(true);
		let response = await indexBalanceRequests({ headers, page, pageSize });
		setDeposits(response.data);
		setTotalItems(response.totalItems);
		setLoading(false);
	}
	const getDetailAgentTechnical = async () => {
		let response = await showTechnicalAgents({ headers, agent_technical_id });
		if (response)
			setAgentTechnical(response);
	}
	const openOrCloseAdd = () => setModalAdd(!modalAdd);

	const downloadVoucher = async (item) => {
		let { id, agent_technical, carrier, created_at } = item;
		downloadFile({
			headers,
			url: `/balance_requests/download/${id}`,
			type: 'application/json',
			name: `Comprobante_${agent_technical}_${carrier}_${created_at}`,
			toast
		});
	}
	const changePage = (page, pageSize) => {
		setPage(page);
		setPageSize(pageSize);
	}
	return (
		<Fragment>
			<Heading className='mb-3'>Comprobantes</Heading>
			<TableComp
				headers={['#', 'Carrier', 'Cantidad', 'Estado', 'Creado en', 'Acciones']}
				keys={['#', 'carrier', 'amount', 'status', 'created_at']}
				body={deposits}
				loading={loading}
				loadingItems={pageSize}
				page={page}
				actions={[
					{ icon: faFilePdf, variant: 'outline-danger', handleClick: downloadVoucher, tooltip: 'Descargar comprobante' }
				]}
				icons={[
					{ value: 'failed', icon: <FontAwesomeIcon icon={faBan} color='red' /> },
					{ value: 'pending', icon: <FontAwesomeIcon icon={faHourglassHalf} color='orange' /> },
					{ value: 'success', icon: <FontAwesomeIcon icon={faCheckDouble} color='green' /> }
				]}
			/>
			<PaginationComp totalItems={totalItems} current={page} onChange={changePage} />
			{/* Botón para abrir modal ADD */}
			<FlexButton style={{ bottom: 40, right: 40 }} onClick={openOrCloseAdd} />
			{/* Modal para agregar comprobante */}
			{
				modalAdd && <Add
					headers={headers}
					onClose={openOrCloseAdd}
					update={getDeposits}
					agent_technical_id={agent_technical_id}
					agent_technical={agentTechnical}
				/>
			}
		</Fragment>
	)
}

export default Comprobantes