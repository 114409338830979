import { Select, useToast } from '@chakra-ui/react';
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import React, { Fragment, useEffect, useState } from 'react'
import { Col, Row, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { indexBalances } from '../../../api/Balances';
import { indexCarrierPlan } from '../../../api/CarrierPlan';
import { transaction } from '../../../api/Drissly';
import { indexProducts } from '../../../api/Products';
import { storeRecharges } from '../../../api/Recharges';
import setToast from '../../../libs/Toast';
import ButtonIcon from '../../layouts/ButtonIcon';
import FormIcon from '../../layouts/FormIcon';
import ModalComp from '../../layouts/Modal'
import SkeletonStack from '../../layouts/Skeletons/Stack';

const _ = require('underscore');

const data_struct = [
    { key: 'phone', name: 'Número telefónico' },
    { key: 'id_product', name: 'Producto' }
];

const ModalChoose = (props) => {
    const { onClose, carrier_id, headers, user_id } = props;
    const token_drissly = useSelector(state => state.login.token_drissly);
    const headers_d = { Authorization: `Bearer ${token_drissly}` };
    const toast = useToast();
    const [plans, setPlans] = useState([]);
    const [products, setProducts] = useState([]);
    const [productsSel, setProductsSel] = useState([]);
    const [loading, setLoading] = useState(true);           // Obteniendo datos 
    const [planSel, setPlanSel] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [data, setData] = useState({});
    const [balances, setBalances] = useState({});

    useEffect(() => {
        getBalances();
        getData();
    }, []);

    const getData = async () => {
        let response = await indexCarrierPlan({ headers, carrier_id });
        setPlans(response.data);
        response = await indexProducts({ headers, carrier_id });
        setProducts(response.data);
        setLoading(false);
    }

    const getBalances = async () => {
        let response = await indexBalances({ headers });
        setBalances(response.data);

    }

    const cancel = () => {
        onClose();
    }
    const getTitle = () => {
        let response = '';
        switch (carrier_id) {
            case 1: response = 'TELCEL'; break;
            case 2: response = 'MOVISTAR'; break;
            case 3: response = 'AT&T'; break;
            default: break;
        }
        return response;
    }
    const changePlan = (e) => {
        let { value } = e.target;
        if (value !== -1) {
            let p = products.filter((product) => {
                return value == product.carrier_plan_id
            });
            setProductsSel(p)
        }
        setPlanSel(value);
    }

    const handleChange = (e) => {
        let { value, name } = e.target;
        setData({ ...data, [name]: value });
    }

    const validateFields = () => {
        let response = true;
        for (let index = 0; index < data_struct.length; index++) {
            const { key, name } = data_struct[index];
            if (!data.hasOwnProperty(key)) {
                response = false;
                setToast({
                    toast, title: '¡Formulario incompleto!', status: 'warning',
                    description: `El campo que corresponde al ${name} no puede estar vacío.`
                });
                break;
            }
        }
        return response;
    }
    // Validar que el saldo restante es suficiente
    const validateBalance = () => {
        let response = false;
        let pos = _.findIndex(balances, { carrier: getTitle() });
        if (pos !== -1) {
            let s_balance = balances[pos];
            let p = _.findIndex(products, { id_product: parseInt(data.id_product) });
            let product = { amount: 100000 };
            if (p !== -1) {
                product = products[p];
            }
            if (s_balance.balance_remain >= product.amount) response = true
            else
                setToast({
                    toast, title: '¡Saldo insuficiente!', status: 'warning',
                    description: `El saldo disponible en su monedero es de $${s_balance.balance_remain} por lo que no se puede efectuar la operación.`
                });
        }
        return response;
    }
    const recharge = async () => {
        if (validateFields() && validateBalance()) {
            setLoading(true);
            let response = await transaction({ headers: headers_d, data });
            let data_success = {};
            if (response) {
                if (response.code === 200) {
                    let { folio, autcarrier } = response;
                    data_success = {
                        isDone: true,
                        folio,
                        autcarrier,
                    }
                    setToast({
                        toast, title: '¡Operación exitosa!', status: 'success',
                        description: `La recarga se completo satisfactoriamente.`
                    });
                } else {
                    setToast({
                        toast, title: '¡La operación no se pudo completar!', status: 'warning',
                        description: response.message
                    });
                }
            } else {
                setToast({
                    toast, title: '¡Operación fallida!', status: 'warning',
                    description: `La recarga no se pudo completar. Comuníquese con el administrador.`
                });
            }
            let pos = _.findIndex(products, { id_product: parseInt(data.id_product) });
            let product = {};
            if (pos !== -1) {
                product = products[pos];
            }
            await storeRecharges({
                headers,
                data: {
                    ...data,
                    ...data_success,
                    amount: product?.amount,
                    product_id: product?.id,
                    checker_id: user_id,
                }
            });
            onClose();
            setLoading(false);
        }
    }
    return (
        <ModalComp
            onClose={onClose}
            title={getTitle()}
            size={'md'}
            body={<Fragment>
                {
                    loading ? <SkeletonStack rows={5} /> : <Fragment>
                        <Row className='mb-3'>
                            <FormIcon title='Número telefónico' name='phone' value={data?.phone} handleChange={handleChange} />
                        </Row>
                        <Row className='mb-3'>
                            <Col>
                                <Form.Label>Planes</Form.Label>
                                <Select onChange={changePlan}>
                                    <option value={-1}>-- Selecccionar plan</option>
                                    {
                                        plans.map((plan, index) =>
                                            <option key={index} value={plan.id}>{plan.name}</option>
                                        )
                                    }
                                </Select>
                            </Col>
                        </Row>
                        {
                            planSel != -1 &&
                            <Row className='mb-3'>
                                <Col>
                                    <Form.Label>Recarga</Form.Label>
                                    <Select name='id_product' value={data?.id_product} onChange={handleChange}>
                                        <option value={-1}>-- Seleccciona la recarga</option>
                                        {
                                            productsSel.map((product, index) =>
                                                <option key={index} value={product.id_product}>{product.name}</option>
                                            )
                                        }
                                    </Select>
                                </Col>
                            </Row>
                        }
                    </Fragment>
                }
            </Fragment>}
            footer={<Fragment>
                <ButtonIcon name='Cancelar' variant='outline-danger' icon={faTimes} onClick={cancel} />
                <ButtonIcon name='Guardar' variant='outline-success' icon={faSave} onClick={recharge} disabled={loading} />
            </Fragment>}
        />
    )
}

export default ModalChoose