import { Result } from 'antd';
import { Container } from "react-bootstrap";
import { useHistory } from "react-router";
import ButtonIcon from '../layouts/ButtonIcon';

const PageNotFound = () => {
    const history = useHistory();

    const handleBack = () => {
        history.push('/');
    }

    return (
        <Container id='pagenotfound-component'>
            <Result
                status="404"
                title='Página no encontrada [error 404]'
                subTitle='Lo sentimos, la página a la que intentas acceder no existe.'
                extra={
                    <ButtonIcon
                        variant='outline-primary'
                        onClick={handleBack}
                        name='Regresar al inicio'
                    />
                }
            />
        </Container>
    );
}

export default PageNotFound;