import { Box, Heading, Image, Text } from '@chakra-ui/react';
import movistar_logo from '../../../images/movistar.png';
import telcel_logo from '../../../images/telcel.png';
import att_logo from '../../../images/att.png';
import services_logo from '../../../images/services.jpg';

const Item = (props) => {
    const getLogo = () => {
        const { carrier } = props.balance;
        let logo = null;
        switch(carrier){
            case 'TELCEL': logo = telcel_logo; break;
            case 'MOVISTAR': logo = movistar_logo; break;
            case 'AT&T': logo = att_logo; break;
            case 'SERVICIOS': logo = services_logo; break;
            default: logo= services_logo;
        }
        return logo;
    }
    return (
        <Box maxW='sm' minH={80} borderWidth='1px' borderRadius='lg' overflow='hidden' style={{ backgroundColor: '#FFF' }}>
            <Image src={getLogo()} />
            <Box p='6' alignContent={'flex-end'}>
                <Text>Saldo disponible [{props?.balance?.carrier}]:</Text>
                <Heading>${props?.balance?.balance_remain}</Heading>
            </Box>
        </Box>
    )
}

export default Item