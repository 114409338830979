import { Box, Center, Image, useToast } from '@chakra-ui/react'
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons'
import React, { Fragment, useState } from 'react'
import { Row } from 'react-bootstrap'
import ButtonIcon from '../layouts/ButtonIcon'
import FormIcon from '../layouts/FormIcon'
import fastpay_logo from '../../images/fastpay_logo.jpg';
import { login } from '../../api/Authenticate'
import { useDispatch } from 'react-redux'
import { OPEN_SESSION } from '../../redux/actions/main'
import setToast from '../../libs/Toast'
import VerificationCode from './VerificationCode'
import { showLog } from '../../libs/Logs'

const Login = () => {
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showVerCode, setShowVerCode] = useState(false);      // Mostrar interfaz para captura de código de verificación
    const [loading, setLoading] = useState(false);

    const toast = useToast();

    const validateData = () => {
        let response = true;
        return response;
    }
    const sendData = async () => {
        setLoading(true);
        if (validateData()) {
            let response = await login(email, password);
            showLog({ message: 'Login: ', obj: response });
            if (response.status === 'SUCCESS') {
                const { user } = response.data;
                if (user.has_two_factor) { // Mostrar interfaz para introducir el codígo de acceso
                    setShowVerCode(true);
                } else {
                    goHome(response.data)
                    setToast({
                        toast, title: '¡Autenticación correcta!', status: 'success',
                        description: 'Ha iniciado sesión de manera satisfactoria'
                    });
                }
            } else {
                setToast({
                    toast, title: '¡Autenticación incorrecta!', status: 'warning',
                    description: response.message
                });
            }
        }
        setLoading(false);
    }
    const goHome = (data) => {
        const { token, user, role, token_drissly } = data;
        dispatch({
            type: OPEN_SESSION,
            session: 'active',
            token,
            token_drissly,
            user,
            role
        });
    }
    return (
        <Fragment>
            <Center className='mt-5'>
                <Box maxW='md' borderWidth='1px' borderRadius='lg' overflow='hidden'>
                    <Image src={fastpay_logo} />
                    {
                        showVerCode ? <VerificationCode email={email} goHome={goHome} /> : <Fragment>
                            <Row className='ms-3 me-3 mb-3'>
                                <FormIcon
                                    title='Correo electrónico'
                                    value={email}
                                    handleChange={(e) => setEmail(e.target.value)}
                                />
                            </Row>
                            <Row className='ms-3 me-3 mb-3'>
                                <FormIcon
                                    title='Contraseña'
                                    type='password'
                                    value={password}
                                    handleChange={(e) => setPassword(e.target.value)}
                                />
                            </Row>
                            <Row className='ms-3 me-3 mb-3'>

                                <div className="d-grid mb-5">
                                    <ButtonIcon
                                        loading={loading}
                                        name='Iniciar sesión'
                                        size='md'
                                        variant='outline-success'
                                        icon={faSignInAlt}
                                        onClick={sendData}
                                    />
                                </div>
                            </Row>
                        </Fragment>
                    }
                </Box>
            </Center>
        </Fragment >
    )
}

export default Login