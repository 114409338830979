import { Box, FormLabel, Input, Select } from '@chakra-ui/react';
import { useState } from 'react'
import { useEffect } from 'react';
import { indexTechnicalAgents } from '../../../api/TechnicalAgents';
import DrawerComp from '../../layouts/DrawerComp'

const SearchFilters = (props) => {
    const { searchByFilters, filters, setFilters, headers, user_id } = props;
    const [agentTechnicals, setAgentTechnicals] = useState([]);
    
    useEffect(() => {
        getTechnicalAgents();
    }, []);

    const getTechnicalAgents = async () => {
        let response = await indexTechnicalAgents({ headers, user_id });
        setAgentTechnicals(response.data);
    }
    const handleChange = (e) => {
        let { name, value } = e.target;
        setFilters({ ...filters, [name]: value });
    }
    return (
        <DrawerComp title='Filtrar depósitos' searchByFilters={searchByFilters}>
            {/* Agente técnico */}
            <Box className='mb-3'>
                <FormLabel>Agente técnico</FormLabel>
                <Select defaultValue={-1} name='agent_technical_id'
                    value={filters?.agent_technical_id} onChange={handleChange}>
                    <option value={-1}>Todos</option>
                    {
                        agentTechnicals.map((agent, index) =>
                            <option key={index} value={agent.id}>{agent.first_name} {agent.last_name}</option>
                        )
                    }
                </Select>
            </Box>
            {/* Estado */}
            <Box className='mb-3'>
                <FormLabel>Estado</FormLabel>
                <Select defaultValue={-1} name='status'
                    value={filters?.status} onChange={handleChange}>
                    <option value={-1}>Todos</option>
                    <option value="pending">Pendientes</option>
                    <option value="failed">Rechazados</option>
                    <option value="success">Aprobados</option>
                </Select>
            </Box>
            {/* Tipo de monedero */}
            {/* <Box className='mb-3'>
                <FormLabel>Monedero</FormLabel>
                <Select defaultValue={-1} name='balance_id'
                    value={filters?.balance_id} onChange={handleChange}>
                    <option value={-1}>Todos</option>

                </Select>
            </Box> */}
            {/* Fecha mínima */}
            <Box className='mb-3'>
                <FormLabel>A partir de</FormLabel>
                <Input type='date' name='start_date' value={filters?.start_date} onChange={handleChange} />
            </Box>
            {/* Fecha maxima */}
            <Box className='mb-3'>
                <FormLabel>Hasta</FormLabel>
                <Input type='date' name='end_date' value={filters?.end_date} onChange={handleChange} />
            </Box>
        </DrawerComp>
    )
}

export default SearchFilters