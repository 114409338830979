import Fetcher from "../libs/Fetcher"
import { showLog } from "../libs/Logs";

const STATUS_OK = 200;
const CREATED = 201;
const url = '/agent_technicals';

export const indexTechnicalAgents = async ({ headers, user_id, page, pageSize }) => {
    let response = { data: [], totalItems: 0 };
    try {
        let params = { user_id };
        if (page && pageSize)
            params = { ...params, page, pageSize }
        let fetch = await Fetcher({
            method: 'GET',
            url: '/agent_technicals',
            headers,
            params
        });
        showLog({ message: `Index ${url}: `, obj: fetch });
        if (fetch.status === STATUS_OK)
            response = fetch.data;
    } catch (error) {
        response = { data: [], totalItems: 0 };
    } finally {
        return response;
    }
}

export const storeTechnicalAgents = async ({ headers, data }) => {
    let response = false;
    try {
        let fetch = await Fetcher({
            method: 'POST',
            url: `/agent_technicals`,
            data,
            headers
        });
        showLog({ message: `Store ${url}: `, obj: fetch });
        if (fetch.status === CREATED)
            response = fetch.data
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}

export const showTechnicalAgents = async ({ headers, agent_technical_id }) => {
    let response = false;
    try {
        let fetch = await Fetcher({
            method: 'GET',
            url: `/agent_technicals/${agent_technical_id}`,
            headers
        });
        showLog({ message: `Show ${url}: `, obj: fetch });
        if (fetch.status === STATUS_OK)
            response = fetch.data.data
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}

export const updateTechnicalAgents = async ({ headers, agent_id, data }) => {
    let response = false;
    try {
        let fetch = await Fetcher({
            method: 'PUT',
            url: `/agent_technicals/${agent_id}`,
            data,
            headers
        });
        showLog({ message: `Update ${url}: `, obj: fetch });
        if (fetch.status === STATUS_OK)
            response = fetch.data.data
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}