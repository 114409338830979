import Fetcher from "../libs/Fetcher"

const url = '/products';

const STATUS_OK = 200;
const CREATED = 201;

export const indexProducts = async ({ headers, page, pageSize, carrier_id }) => {
    let response = { data: [], totalItems: 0 };
    try {
        let params = {carrier_id};
        if (page && pageSize)
            params = { page, pageSize }
        let fetch = await Fetcher({
            method: 'GET',
            url: `${url}`,
            headers,
            params
        });
        console.log(`Index ${url}`, fetch);
        if (fetch.status === STATUS_OK)
            response = fetch.data;
    } catch (error) {
        response = { data: [], totalItems: 0 };
    } finally {
        return response;
    }
}

export const storeProducts = async ({ headers, data }) => {
    let response = false;
    try {
        let fetch = await Fetcher({
            method: 'POST',
            url: `${url}`,
            data,
            headers
        });
        console.log(`Store ${url}`, fetch);
        if (fetch.status === CREATED)
            response = fetch.data.data
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}

export const showProducts = async ({ headers, recharge_id }) => {
    let response = false;
    try {
        let fetch = await Fetcher({
            method: 'GET',
            url: `${url}/${recharge_id}`,
            headers
        });
        console.log(`Show ${url}`, fetch);
        if (fetch.status === STATUS_OK)
            response = fetch.data.data
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}

export const updateProducts = async ({ headers, recharge_id, data }) => {
    let response = false;
    try {
        let fetch = await Fetcher({
            method: 'PUT',
            url: `${url}/${recharge_id}`,
            data,
            headers
        });
        console.log(`Update ${url}`, fetch);
        if (fetch.status === STATUS_OK)
            response = fetch.data.data
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}