import { Layout, Menu } from 'antd';
import { useState, Fragment, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { HomeOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { faCashRegister, faCogs, faCoins, faCommentDollar, faFileInvoiceDollar, faHistory, faSpinner, faTicketAlt, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../styles/SidebarComponent.scss';

const { Sider } = Layout;

const Sidebar = (props) => {
    const [collapsed, setCollapsed] = useState(false);  // Menu colapsable

    const role = useSelector(state => state.login.role);

    const onCollapse = collapsed => {
        collapsed ? props.setMarginLeft(80) : props.setMarginLeft(200);
        setCollapsed(collapsed);
    }
    const isClient = useIsClient();

    const handleClick = e => {
        // console.log('click menu', e);
    }

    return (
        <Sider collapsible collapsed={collapsed} onCollapse={onCollapse} className='scroll-sidebar scroll-body'>
            <div className="logo" />
            {
                isClient &&
                <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline" onClick={handleClick}>
                    <Menu.Item key="1" icon={<HomeOutlined />}>
                        <Link to="/" /> FastPay
                    </Menu.Item>

                    {/* Rol: Agent */}
                    {
                        role.name === 'AGENTE' && <Fragment>

                            <Menu.Item icon={<FontAwesomeIcon icon={faUsers} />}>
                                <Link to='/agentes-tecnicos' /> Agentes técnicos
                            </Menu.Item>
                            <Menu.Item icon={<FontAwesomeIcon icon={faFileInvoiceDollar} />}>
                                <Link to='/depositos' /> Depósitos
                            </Menu.Item>
                            <Menu.Item icon={<FontAwesomeIcon icon={faCoins} />}>
                                <Link to='/saldo' /> Saldo
                            </Menu.Item>
                            <Menu.Item icon={<FontAwesomeIcon icon={faCoins} />}>
                                <Link to='/movimientos' /> Movimientos
                            </Menu.Item>
                            {/* <Menu.Item icon={<FontAwesomeIcon icon={faSpinner} />}>
                                <Link to='/pendientes' /> Pendientes
                            </Menu.Item> */}

                        </Fragment>
                    }
                    {/* Rol: Technical Agent */}
                    {
                        role.name === 'AGENTE_TECNICO' && <Fragment>

                            <Menu.Item icon={<FontAwesomeIcon icon={faCashRegister} />}>
                                <Link to='/cajeros' /> Cajeros
                            </Menu.Item>
                            <Menu.Item icon={<FontAwesomeIcon icon={faTicketAlt} />}>
                                <Link to='/comprobantes' /> Comprobantes
                            </Menu.Item>
                            <Menu.Item icon={<FontAwesomeIcon icon={faCoins} />}>
                                <Link to='/saldo-agente-tecnico' /> Saldo
                            </Menu.Item>
                            <Menu.Item icon={<FontAwesomeIcon icon={faHistory} />}>
                                <Link to='/movimientos' /> Movimientos
                            </Menu.Item>
                        </Fragment>
                    }
                    {/* Rol: Checker */}
                    {
                        role.name === 'CAJERO' && <Fragment>

                            <Menu.Item icon={<FontAwesomeIcon icon={faCommentDollar} />}>
                                <Link to='/recargas' /> Recargas
                            </Menu.Item>
                            <Menu.Item icon={<FontAwesomeIcon icon={faFileInvoiceDollar} />}>
                                <Link to='/pago-servicios' /> Pago de servicios
                            </Menu.Item>
                            <Menu.Item icon={<FontAwesomeIcon icon={faHistory} />}>
                                <Link to='/movimientos' /> Movimientos
                            </Menu.Item>
                        </Fragment>
                    }
                    <Menu.Item icon={<FontAwesomeIcon icon={faCogs} />}>
                        <Link to='/config' /> Configuración
                    </Menu.Item>
                </Menu>
            }
        </Sider>
    )
}


const useIsClient = () => {
    const [isClient, setIsClient] = useState(false);
    // The following effect will be ignored on server, 
    // but run on the browser to set the flag true
    useEffect(() => setIsClient(true), []);
    return isClient
}

export default Sidebar