import { Heading } from '@chakra-ui/react'
import { Fragment } from 'react'
import TabsComp from '../layouts/Tabs'
import General from './General'
import Security from './Security'

const ConfigComp = () => {
    return (
        <Fragment>
            <Heading className='mb-3'>Configuración de perfil</Heading>
            <TabsComp
                tablist={['Información general', 'Seguridad']}
                tabpanels={[
                    <General />,
                    <Security />
                ]}
            />
        </Fragment>
    )
}

export default ConfigComp