import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import movistar_logo from '../../../images/movistar.png';
import telcel_logo from '../../../images/telcel.png';
import att_logo from '../../../images/att.png';
import Item from '../Item'
import { Heading } from '@chakra-ui/react';

const Main = (props) => {
    const { setCarrierSel, setView , openOrClose} = props;
    const handleClick = (carrier_id) => {
        setCarrierSel(carrier_id);
        setView('carrier');
        openOrClose();
    }
    return (
        <Container fluid>
            <Heading>Recargas de tiempo aire</Heading>
            <Row className='mt-5'>
                <Col sm={12} md={4} className='mt-3' onClick={() => handleClick(1)}>
                    <Item logo={telcel_logo} />
                </Col>
                <Col sm={12} md={4} className='mt-3' onClick={() => handleClick(2)}>
                    <Item logo={movistar_logo} />
                </Col>
                <Col sm={12} md={4} className='mt-3' onClick={() => handleClick(3)}>
                    <Item logo={att_logo} />
                </Col>
            </Row>
        </Container>
    )
}

export default Main