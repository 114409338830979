import { faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons';
import { Fragment } from 'react';
import Building from '../../layouts/Building';
import ButtonIcon from '../../layouts/ButtonIcon';
import ModalComp from '../../layouts/Modal'

const Details = (props) => {
    const { onClose } = props;
    return (
        <ModalComp
            title='Detalles del cajero'
            size='lg'
            onClose={onClose}
            body={<Fragment>
                <Building />
            </Fragment>}
            footer={<Fragment>
                <ButtonIcon name='Cerrar' icon={faAngleDoubleLeft} onClick={onClose} variant='outline-secondary' />
            </Fragment>}
        />
    )
}

export default Details