import Fetcher from "../libs/Fetcher"
import { showLog } from "../libs/Logs";

const STATUS_OK = 200;

export const login = async (email, password) => {
    let response = false;
    try {
        let fetch = await Fetcher({
            method: 'POST',
            url: '/login',
            data: {
                email,
                password
            }
        });
        if (fetch.status === STATUS_OK)
            response = {
                status: 'SUCCESS',
                data: fetch.data.data
            };
        else
            response = {
                status: 'FAILED',
                message: `${response.data.errors.msg[0]}`
            };
        showLog({ message: `Login fetcher: `, obj: fetch });
    } catch (error) {
        response = {
            status: 'FAILED',
            message: 'Error al conectar con el servidor'
        };
    } finally {
        return response;
    }
}


export const verify = async ({ email, code }) => {
    let response = { res: false, data: {} };
    try {
        let fetch = await Fetcher({
            method: 'POST',
            url: '/verify',
            data: { email, code }
        });
        showLog({message: 'Fetcher verify code: ', obj: fetch});
        if (fetch.status === 200)
            response = { res: true, data: fetch.data.data }
    } catch (error) {
        response = { res: false, data: {} };
    } finally {
        return response;
    }
}

export const resendCode = async ({ email }) => {
    let response = { res: false, data: {} };
    try {
        let fetch = await Fetcher({
            method: 'POST',
            url: '/resend_code',
            data: { email }
        });
        showLog({message: 'Fetcher resend code: ', obj: fetch});
        if (fetch.status === 200)
            response = fetch.data
    } catch (error) {
        showLog({message: 'Fetcher resend code [error]: ', obj: error});
        response = { res: false, data: {} };
    } finally {
        return response;
    }
}

export const logout = async ({ headers }) => {
    try {
        let fetch = await Fetcher({
            method: 'GET',
            url: '/logout',
            headers
        });
        showLog({ message: `Logout fetcher: `, obj: fetch });
    } catch (error) {
        showLog({ message: `Error al cerrar sesión: `, obj: fetch });
    }
}