import React from 'react'
import { Container } from 'react-bootstrap'
import Balances from './Balances'

const Agent = () => {
  return (
    <Container fluid>
      <div style={{ width: "100%", height: "80vh" }}>
        <iframe
          src="https://metabase.victum-re.online/public/dashboard/e5aa4728-6f59-40c2-8bcd-a02e2fa1713d"
          frameborder="0"
          width="100%"
          height="100%"
          allowtransparency
        ></iframe>
      </div>
    </Container>
  )
}

export default Agent