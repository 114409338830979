import Fetcher from "../libs/Fetcher"
import { showLog } from "../libs/Logs";

const url = '/users';

const STATUS_OK = 200;
const CREATED = 201;

export const indexUsers = async ({ headers, page, pageSize }) => {
    let response = { data: [], totalItems: 0 };
    try {
        let params = {};
        if (page && pageSize)
            params = { page, pageSize }
        let fetch = await Fetcher({
            method: 'GET',
            url: `${url}`,
            headers,
            params
        });
        showLog({ message: `Index ${url}`, obj: fetch });
        if (fetch.status === STATUS_OK)
            response = fetch.data;
    } catch (error) {
        response = { data: [], totalItems: 0 };
    } finally {
        return response;
    }
}

export const storeUsers = async ({ headers, data }) => {
    let response = false;
    try {
        let fetch = await Fetcher({
            method: 'POST',
            url: `${url}`,
            data,
            headers
        });
        showLog({ message: `Store ${url}`, obj: fetch });
        if (fetch.status === CREATED)
            response = fetch.data.data
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}

export const showUsers = async ({ headers, user_id }) => {
    let response = false;
    try {
        let fetch = await Fetcher({
            method: 'GET',
            url: `${url}/${user_id}`,
            headers
        });
        showLog({ message: `Show ${url}`, obj: fetch });
        if (fetch.status === STATUS_OK)
            response = fetch.data.data
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}

export const updateUsers = async ({ headers, user_id, data }) => {
    let response = false;
    try {
        let fetch = await Fetcher({
            method: 'PUT',
            url: `${url}/${user_id}`,
            data,
            headers
        });
        showLog({ message: `Update ${url}`, obj: fetch });
        if (fetch.status === STATUS_OK)
            response = fetch.data.data
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}