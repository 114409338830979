import { Redirect, Route } from "react-router-dom";
import { useSelector } from "react-redux";

const _ = require('underscore');

const PrivateRoute = ({ component: Component, role, code, ...rest }) => {
    const roles = useSelector(state => state.login.roles);

    // Buscar si el usuario actual tiene acceso al Rol solicitado
    const isRole = () => {
        let pos = _.findIndex(roles, { code: role });
        return pos !== -1 ? true : false;
    }
    // Verificar si además tiene permiso para acceder al componente
    const hasPermission = () => {
        let response = true;                                        // Default TRUE por si no se especifica el código del componente
        if (code) {                                                 // Si se manda el código del componene
            let pos = _.findIndex(roles, { code: role });           // Se busca a que rol se hace referencia
            if (pos !== -1) {
                pos = _.findIndex(roles[pos].modules, { code });    // Módulos a los que tiene accesso el rol
                if (pos === -1) response = false;                   // FALSE si no se encuentra el módulo
            }
        }
        return response;
    }

    return (
        <Route
            {...rest}
            render={props =>
                isRole() && hasPermission() ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: "/permission-denied",
                            state: { from: props.location }
                        }}
                    />
                )
            }
        />
    );
}

export default PrivateRoute;