import { Heading } from '@chakra-ui/react'
import { faEdit, faEye } from '@fortawesome/free-solid-svg-icons';
import { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { indexCheckers } from '../../api/Checkers';
import FlexButton from '../layouts/FlexButton';
import TableComp from '../layouts/TableComp'
import Add from './Cajeros/Add';
import Details from './Cajeros/Details';
import Edit from './Cajeros/Edit';

const Cajeros = () => {
    const login = useSelector(state => state.login);
    const headers = { Authorization: `Bearer ${login.token}` };
    const user_id = login.user.id;

    const [checkers, setCheckers] = useState([]);
    const [checkerSel, setCheckerSel] = useState({});
    const [loading, setLoading] = useState(false);
    const [modalEdit, setModalEdit] = useState(false);
    const [modalView, setModalView] = useState(false);
    const [modalAdd, setModalAdd] = useState(false);

    useEffect(() => {
        getCheckers();
    }, []);

    const getCheckers = async () => {
        setLoading(true);
        let response = await indexCheckers({ headers, user_id });
        setCheckers(response.data);
        setLoading(false);
    }

    const openOrCloseEdit = () => setModalEdit(!modalEdit);
    const openOrCloseView = () => setModalView(!modalView);
    const openOrCloseAdd = () => setModalAdd(!modalAdd);

    const viewChecker = (item) => {
        setCheckerSel(item);
        openOrCloseView();
    }
    const editChecker = (item) => {
        setCheckerSel(item);
        openOrCloseEdit();
    }
    return (
        <Fragment>
            <Heading className='mb-3'>Cajeros</Heading>
            <TableComp
                headers={['#', 'Cajero', 'Correo electrónico', 'Creado en', 'Acciones']}
                keys={['#', 'full_name', 'email', 'created_at']}
                body={checkers}
                loading={loading}
                actions={[
                    { icon: faEdit, tooltip: 'Editar datos de Cajero', handleClick: editChecker, variant: 'outline-success' },
                    { icon: faEye, tooltip: 'Ver detalles de Cajero', handleClick: viewChecker, variant: 'outline-primary' },
                ]}
            />
            {/* Botón para abrir modal ADD */}
			<FlexButton style={{ bottom: 40, right: 40 }} onClick={openOrCloseAdd} />
			{/* Modal para agregar cajero */}
			{
				modalAdd && <Add
					onClose={openOrCloseAdd}
					headers={headers}
					update={getCheckers}
				/>
			}
			{/* Modal para ver detalles de cajero */}
			{
				modalView && <Details
					onClose={openOrCloseView}
				/>
			}
			{/* Modal para editar datos de cajero */}
			{
				modalEdit && <Edit
					onClose={openOrCloseEdit}
					headers={headers}
					checker={checkerSel}
					update={getCheckers}
				/>
			}
        </Fragment>
    )
}

export default Cajeros