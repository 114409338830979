import { CLOSE_SESSION, OPEN_SESSION } from '../actions/main';
import { stateLogin as initialState } from '../initialState/main';

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case OPEN_SESSION:
            return {
                ...state,
                session: action.session,
                token: action.token,
                token_drissly: action.token_drissly,
                role: action.role,
                user: action.user,
            };
        case CLOSE_SESSION:
            return {
                state: undefined
            };
        default: return state;
    }
}

export default reducer;