import { Select, useToast } from '@chakra-ui/react';
import { faAngleDoubleLeft, faSave } from '@fortawesome/free-solid-svg-icons';
import { Fragment, useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { storeBalanceRequests } from '../../../api/BalanceRequests';
import setToast from '../../../libs/Toast';
import ButtonIcon from '../../layouts/ButtonIcon';
import FormIcon from '../../layouts/FormIcon';
import ModalComp from '../../layouts/Modal'

const data_struct = [
    { key: 'amount', name: 'Monto' },
    { key: 'balance_id', name: 'Carrier' },
    { key: 'voucher_file', name: 'Comprobante de depósito' },
];

const Add = (props) => {
    const { onClose, headers, update, agent_technical_id, agent_technical } = props;
    const [data, setData] = useState({});
    const toast = useToast();

    useEffect(() => {
        if (agent_technical?.balances.length > 0)
            setData({balance_id: agent_technical.balances[0].id});
    }, []);

    const validate = () => {
        let response = true;
        for (let index = 0; index < data_struct.length; index++) {
            const { key, name } = data_struct[index];
            if (!data.hasOwnProperty(key)) {
                response = false;
                setToast({
                    toast, title: '¡Formulario incompleto!', status: 'warning',
                    description: `El campo que corresponde al ${name} no puede estar vacío.`
                });
                break;
            }
        }
        return response;
    }
    const saveAgent = async () => {
        if (validate()) {
            let formData = new FormData();
            formData.append('balance_id', data.balance_id);
            formData.append('amount', data.amount);
            formData.append('voucher_file', data.voucher_file);
            formData.append('agent_technical_id', agent_technical_id);
            console.log("FormData: ", formData.voucher_file);
            let response = await storeBalanceRequests({ headers, data: formData });
            if (response) {
                update();
                setToast({
                    toast, title: '¡Operación exitosa!', status: 'success',
                    description: 'El comprobante ha sido agregado con éxito.'
                });
                onClose();
            } else {
                setToast({
                    toast, title: '¡Operación fallida!', status: 'error',
                    description: 'Ha ocurrido un error al tratar de agregar el comprobante.'
                });
            }
        }
    }
    const handleChange = (e) => {
        let { value, name } = e.target;
        setData({ ...data, [name]: value });
    }

    const handleLoadFile = (e) => {
        let { name } = e.target;
        setData({ ...data, [name]: e.target.files[0] });
    }

    return (
        <ModalComp
            title='Agregar cajero'
            size='md'
            onClose={onClose}
            body={<Fragment>
                <Row className='mb-3'>
                    <FormIcon title='Cantidad' name='amount' value={data?.amount} handleChange={handleChange} type='number' />
                </Row>
                <Row className='mb-3'>
                    <Col>
                        <Form.Label>Carrier</Form.Label>
                        <Select name='balance_id' onChange={handleChange} value={data?.balance_id}>
                            {
                                agent_technical?.balances.map((b, i) =>
                                    <option key={i} value={b.id}>{b.carrier}</option>
                                )
                            }
                        </Select>
                    </Col>
                </Row>
                <Row className='mb-3'>
                    <FormIcon title='Comprobante' name='voucher_file' handleChange={handleLoadFile} type='file' />
                </Row>
            </Fragment>}
            footer={<Fragment>
                <ButtonIcon name='Cerrar' icon={faAngleDoubleLeft} onClick={onClose} variant='outline-secondary' />
                <ButtonIcon name='Agregar' icon={faSave} onClick={saveAgent} variant='outline-success' />
            </Fragment>}
        />
    )
}

export default Add