import { Heading, useToast } from '@chakra-ui/react'
import { faBan, faCheck, faFileDownload, faSpinner, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row } from 'antd';
import { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { indexBalanceRequests } from '../../api/BalanceRequests';
import downloadFile from '../../libs/Download';
import PaginationComp from '../layouts/Pagination';
import TableComp from '../layouts/TableComp'
import SearchFilters from './Deposit/Filters';
import Review from './Deposit/Review';

const Desposit = () => {
	const login = useSelector(state => state.login);
	const headers = { Authorization: `Bearer ${login.token}` };
	const toast = useToast();
	const [modalReview, setModalReview] = useState(false);
	const [deposits, setDeposits] = useState([]);
	const [depositSel, setDepositSel] = useState({});
	const [loading, setLoading] = useState(false);
	const [type, setType] = useState('');
	const [totalItems, setTotalItems] = useState(0);
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [filters, setFilters] = useState({});

	useEffect(() => {
		getDeposits();
	}, [page, pageSize]);

	const getDeposits = async () => {
		setLoading(true);
		let response = await indexBalanceRequests({ headers, page, pageSize, ...filters });
		setDeposits(response.data);
		setTotalItems(response.totalItems);
		setLoading(false);
	}
	const openOrCloseReview = () => setModalReview(!modalReview);

	const aproveDeposit = (item) => {
		setDepositSel(item);
		setType('Autorizar');
		openOrCloseReview();
	}
	const rejectDeposit = (item) => {
		setDepositSel(item);
		setType('Rechazar');
		openOrCloseReview();
	}
	const downloadVoucher = async (item) => {
		let { id, agent_technical, carrier, created_at } = item;
		downloadFile({
			headers,
			url: `/balance_requests/download/${id}`,
			type: 'application/json',
			name: `Comprobante_${agent_technical}_${carrier}_${created_at}`,
			toast
		});
	}
	const changePage = (page, pageSize) => {
		setPage(page);
		setPageSize(pageSize);
	}

	const searchByFilters = () => {
		setPage(1);
		getDeposits();
	}

	return (
		<Fragment>
			<Row justify='space-between' align='middle' className='mb-2'>
				<Col flex={4}>
					<Heading className='mb-3'>Depositos</Heading>
				</Col>
				<Col flex={0}>
					<SearchFilters
						searchByFilters={searchByFilters}
						filters={filters}
						setFilters={setFilters}
						headers={headers}
						user_id={login.user.id}
					/>
				</Col>
			</Row>
			<TableComp
				headers={['#', 'Agente técnico', 'Cantidad', 'Monedero', 'Estado', 'Creado en', 'Acciones']}
				keys={['#', 'agent_technical', 'amount', 'carrier', 'status', 'created_at']}
				body={deposits}
				loading={loading}
				loadingItems={pageSize}
				page={page}
				actions={[
					{ icon: faFileDownload, variant: 'outline-primary', tooltip: 'Descargar comprobante de depósito', handleClick: downloadVoucher },
					{ icon: faCheck, variant: 'outline-success', tooltip: 'Autorizar depósito', handleClick: aproveDeposit, dinamic: [{ key: 'status', value: 'pending' }] },
					{ icon: faTimes, variant: 'outline-danger', tooltip: 'Rechazar depósito', handleClick: rejectDeposit, dinamic: [{ key: 'status', value: 'pending' }] }
				]}
				icons={[
					{ value: 'pending', icon: <FontAwesomeIcon icon={faSpinner} color='blue' /> },
					{ value: 'failed', icon: <FontAwesomeIcon icon={faBan} color='red' /> },
					{ value: 'success', icon: <FontAwesomeIcon icon={faCheck} color='green' /> }
				]}
				currency={[{ index: 2 }]}
			/>
			<PaginationComp totalItems={totalItems} current={page} onChange={changePage} />
			{/* Modal para revisar (aprobar o rechazar el deposito) */}
			{
				modalReview && <Review
					onClose={openOrCloseReview}
					deposit={depositSel}
					type={type}
					headers={headers}
					update={getDeposits}
				/>
			}
		</Fragment>
	)
}

export default Desposit