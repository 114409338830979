import Fetcher from "../libs/Fetcher"

const STATUS_OK = 200;
const CREATED = 201;

const url = '/balances';

export const indexBalances = async ({ headers }) => {
    let response = { data: [], totalItems: 0 };
    try {
        let fetch = await Fetcher({
            method: 'GET',
            url: `${url}`,
            headers,
        });
        console.log(`Index ${url}`, fetch);
        if (fetch.status === STATUS_OK)
            response = fetch.data;
    } catch (error) {
        response = { data: [], totalItems: 0 };
    } finally {
        return response;
    }
}