import Fetcher from "../libs/Fetcher"

const STATUS_OK = 200;
const CREATED = 201;

export const indexCheckers = async ({ headers, user_id }) => {
    let response = { data: [], totalItems: 0 };
    try {
        let params = { user_id };
        let fetch = await Fetcher({
            method: 'GET',
            url: '/checkers',
            headers,
            params
        });
        console.log("Index checkers: ", fetch);
        if (fetch.status === STATUS_OK)
            response = fetch.data;
    } catch (error) {
        response = { data: [], totalItems: 0 };
    } finally {
        return response;
    }
}

export const storeCheckers = async ({ headers, data }) => {
    let response = false;
    try {
        let fetch = await Fetcher({
            method: 'POST',
            url: `/checkers`,
            data,
            headers
        });
        console.log("create checkers: ", fetch);
        if (fetch.status === CREATED)
            response = fetch.data
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}

export const updateCheckers = async ({ headers, checker_id, data }) => {
    let response = false;
    try {
        let fetch = await Fetcher({
            method: 'PUT',
            url: `/checkers/${checker_id}`,
            data,
            headers
        });
        console.log("update checkers: ", fetch);
        if (fetch.status === STATUS_OK)
            response = fetch.data.data
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}