import { Fragment, useState } from 'react'
import Main from './Recharges/Main';
import { useSelector } from 'react-redux';
import ModalChoose from './Recharges/Modal';

const Recharges = () => {
    const [view, setView] = useState('main');
    const [carrierSel, setCarrierSel] = useState(null);
    const login = useSelector(state => state.login);
    const headers = { Authorization: `Bearer ${login.token}` };
    const [modal, setModal] = useState(false);

    const openOrClose = () => setModal(!modal);
    return (
        <Fragment>
            {/* Vista principal */}
            <Main
                setCarrierSel={setCarrierSel}
                setView={setView}
                openOrClose={openOrClose}
            />
            {
                modal && <ModalChoose
                    onClose={openOrClose}
                    carrier_id={carrierSel}
                    headers={headers}
                    user_id={login.user.id}
                />
            }

        </Fragment>
    )
}

export default Recharges