import { Fragment } from 'react'
import { useSelector } from 'react-redux'
import Agent from '../1.Agent/Index';
import TechnicalAgent from '../2.TechnicalAgent/Index';
import Checker from '../3.Checker/Index';

const Home = () => {
    const role = useSelector(state => state.login.role);
    // console.log("role: ", role);
    return (
        <Fragment>
            {
                role.name === 'CAJERO' && <Checker />
            }
            {
                role.name === 'AGENTE_TECNICO' && <TechnicalAgent />
            }
            {
                role.name === 'AGENTE' && <Agent />
            }
        </Fragment>
    )
}

export default Home