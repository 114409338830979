import { faEye } from '@fortawesome/free-solid-svg-icons';
import { Col, Row } from 'antd';
import { Fragment, useEffect, useState } from 'react'
import { indexPaymentServices } from '../../../api/PaymentServices';
import PaginationComp from '../../layouts/Pagination';
import TableComp from '../../layouts/TableComp'
import PaymentDetails from './PaymentDetails';
import PaymentFilters from './PaymentFilters';

const ServicesTable = (props) => {
    const { headers } = props;
    const [movements, setMovements] = useState([]);
    const [movementSel, setMovementSel] = useState({});
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [loading, setLoading] = useState(true);
    const [modalDet, setModalDet] = useState(false);
    const [filters, setFilters] = useState({});

    useEffect(() => {
        getMovements();
    }, [page, pageSize]);

    const getMovements = async () => {
        setLoading(true);
        let response = await indexPaymentServices({ headers, page, pageSize, ...filters });
        setMovements(response.data);
        setTotalItems(response.totalItems);
        setLoading(false);
    }

    const changePage = (page, pageSize) => {
        setPage(page);
        setPageSize(pageSize);
    }

    const openOrCloseDet = () => setModalDet(!modalDet);

    const viewDetails = (item) => {
        setMovementSel(item);
        openOrCloseDet();
    }

    const searchByFilters = () => {
        setPage(1);
        getMovements();
    }

    return (
        <Fragment>
            <Row justify='end' align='middle' className='mb-2'>
                <Col flex={0}>
                    <PaymentFilters 
                        filters={filters}
                        setFilters={setFilters}
                        searchByFilters={searchByFilters}
                        headers={headers}
                    />
                </Col>
            </Row>
            <TableComp
                headers={['#', 'Folio', 'Autcarrier', 'Cajero', 'Servicio', 'Teléfono', 'Cantidad', 'Estado', 'Creado en', 'Acciones']}
                keys={['#', 'folio', 'autcarrier', 'checker', 'service', 'phone', 'amount', 'isDone', 'created_at']}
                body={movements}
                loading={loading}
                loadingItems={pageSize}
                page={page}
                actions={[
                    { icon: faEye, variant: 'outline-primary', tooltip: 'Ver detalles', handleClick: viewDetails }
                ]}
                currency={[{ index: 6 }]}
                badges={[
                    { index: 7, color: 'isDone_color' }
                ]}
            />
            <PaginationComp totalItems={totalItems} current={page} onChange={changePage} />
            {
                modalDet && <PaymentDetails
                    onClose={openOrCloseDet}
                    movement={movementSel}
                    headers={headers}
                />
            }
        </Fragment>
    )
}

export default ServicesTable