import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, Text, useToast } from "@chakra-ui/react";
import { faAngleDoubleLeft, faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getDrisslyBalance } from "../../../api/Drissly";
import { updateBalanceRequests } from "../../../api/BalanceRequests";
import setToast from "../../../libs/Toast";
import ButtonIcon from "../../layouts/ButtonIcon";
import ModalComp from "../../layouts/Modal";

const Review = (props) => {
    const { onClose, deposit, type, headers, update } = props;
    const toast = useToast();
    const token_drissly = useSelector(state => state.login.token_drissly);
    const headers_d = { Authorization: `Bearer ${token_drissly}` };
    const [balance, setBalance] = useState({});
    const [grant, setGrant] = useState(false);               // Se autoriza continuar con la operación

    useEffect(() => {
        if (type === 'Autorizar') {
            getCarrierBalance(deposit?.carrier); // obtener saldo del carrier
        } else {
            setGrant(true);
        }
    }, []);

    const getCarrierBalance = async (carrier) => {
        let id_carrier = 0;
        switch (carrier) {
            case 'TELCEL': id_carrier = 1; break;
            case 'MOVISTAR': id_carrier = 2; break;
            case 'AT&T': id_carrier = 3; break;
            case 'SERVICIOS': id_carrier = 4; break;
            default: id_carrier = 0; break;
        }
        let response = await getDrisslyBalance({ headers: headers_d, id_carrier });
        if (response) {
            setBalance(response); // Guardar detalles del saldo
            // Si el saldo disponible es menor al saldo solicitado no se puede continuar con la operación
            if (response.remaining_balance >= deposit.amount)
                setGrant(true);
        }
    }

    const aprove = async () => {
        let response = await updateBalanceRequests({
            headers,
            data: { status: 'success' },
            balance_request_id: deposit.id
        });
        if (response) {
            setToast({
                toast, title: '¡operación exitosa!', status: 'success',
                description: 'La solicitud ha sido aprobada satisfactoriamente.'
            });
            update();
            onClose();
        } else {
            setToast({
                toast, title: '¡Operación fallida!', status: 'error',
                description: 'La operación no se pudo completar, contacte al administrador.'
            });
        }
    }

    const reject = async () => {
        let response = await updateBalanceRequests({
            headers,
            data: { status: 'failed' },
            balance_request_id: deposit.id
        });
        if (response) {
            setToast({
                toast, title: '¡operación exitosa!', status: 'success',
                description: 'La solicitud ha sido rechazada satisfactoriamente.'
            });
            update();
            onClose();
        } else {
            setToast({
                toast, title: '¡Operación fallida!', status: 'error',
                description: 'La operación no se pudo completar, contacte al administrador.'
            });
        }
    }
    return (
        <ModalComp
            title={`${type} depósito`}
            onClose={onClose}
            size='md'
            body={<Fragment>
                {
                    !grant &&
                    <Alert status='warning'>
                        <AlertIcon />
                        <Box>
                            <AlertTitle>¡Saldo insuficiente!</AlertTitle>
                            <AlertDescription>
                                El saldo disponible que tiene en su monedero {balance?.name} es de ${balance?.remaining_balance}, por lo que no puede continuar con esta operación.
                            </AlertDescription>
                        </Box>
                    </Alert>
                }
                <Text>¿Está seguro que desea realizar esta operación?</Text>
                <Text>
                    Usted está apunto de <strong>{type}</strong> el depósito por la cantidad de <strong>${deposit?.amount}</strong> para el monedero de <strong>{deposit?.carrier}</strong> del agente técnico <strong>{deposit.agent_technical}</strong>.
                </Text>
                <Text>Le recordamos que esta operación es irreversible.</Text>
            </Fragment>}
            footer={<Fragment>
                <ButtonIcon name='Cerrar' icon={faAngleDoubleLeft} variant='outline-secondary' onClick={onClose} />
                {
                    type === 'Autorizar' ?
                        <ButtonIcon name='Aprobar' icon={faCheck} variant='outline-success' onClick={aprove} disabled={!grant} /> :
                        <ButtonIcon name='Rechazar' icon={faTimes} variant='outline-danger' onClick={reject} />
                }
            </Fragment>}
        />
    )
}

export default Review