import { Box, FormLabel, Input, Select } from '@chakra-ui/react'
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { indexCheckers } from '../../../api/Checkers';
import { indexTechnicalAgents } from '../../../api/TechnicalAgents';
import DrawerComp from '../../layouts/DrawerComp'

const PaymentFilters = (props) => {
    const { searchByFilters, filters, setFilters, headers } = props;
    const role = useSelector(state => state.login.role);
    const user = useSelector(state => state.login.user);
    const [checkers, setCheckers] = useState([]);
    const [technicals, setTechnicals] = useState([]);

    useEffect(() => {
        switch (role.name) {
            case 'AGENTE':
                getTechnicals(user.id);
                break;
            case 'AGENTE_TECNICO':
                getCheckers(user.id);
                break;
            default: break;
        }
    }, []);

    const getCheckers = async (user_id) => {
        let response = await indexCheckers({ headers, user_id });
        setCheckers(response.data);
    }
    const getTechnicals = async (user_id) => {
        let response = await indexTechnicalAgents({ headers, user_id });
        setTechnicals(response.data)
    }

    const handleChange = (e) => {
        let { name, value } = e.target;
        setFilters({ ...filters, [name]: value });
    }

    return (
        <DrawerComp title='Filtrar pagos' searchByFilters={searchByFilters}>
            {/*  Cajero*/}
            {
                role.name === 'AGENTE' &&
                <Box className='mb-3'>
                    <FormLabel>Agente técnico</FormLabel>
                    <Select defaultValue={-1} name='agent_technical_id'
                        value={filters?.agent_technical_id} onChange={handleChange}>
                        <option value={-1}>Todos</option>
                        {
                            technicals.map((technical, index) =>
                                <option key={index} value={technical.id}>{technical.first_name} {technical.last_name}</option>
                            )
                        }
                    </Select>
                </Box>
            }
            {/*  Cajero*/}
            {
                role.name === 'AGENTE_TECNICO' &&
                <Box className='mb-3'>
                    <FormLabel>Cajero</FormLabel>
                    <Select defaultValue={-1} name='checker_id'
                        value={filters?.checker_id} onChange={handleChange}>
                        <option value={-1}>Todos</option>
                        {
                            checkers.map((checker, index) =>
                                <option key={index} value={checker.id}>{checker.first_name} {checker.last_name}</option>
                            )
                        }
                    </Select>
                </Box>
            }
            {/* Estado */}
            <Box className='mb-3'>
                <FormLabel>Estado</FormLabel>
                <Select defaultValue={-1} name='status'
                    value={filters?.status} onChange={handleChange}>
                    <option value={-1}>Todos</option>
                    <option value={'done'}>Realizados</option>
                    <option value={'failed'}>Fallidos</option>
                </Select>
            </Box>
            {/* Número telefónico */}
            <Box className='mb-3'>
                <FormLabel>Número telefónico</FormLabel>
                <Input type='text' name='phone' value={filters?.phone} onChange={handleChange} maxLength={10} />
            </Box>
            {/* Fecha mínima */}
            <Box className='mb-3'>
                <FormLabel>A partir de</FormLabel>
                <Input type='date' name='start_date' value={filters?.start_date} onChange={handleChange} />
            </Box>
            {/* Fecha maxima */}
            <Box className='mb-3'>
                <FormLabel>Hasta</FormLabel>
                <Input type='date' name='end_date' value={filters?.end_date} onChange={handleChange} />
            </Box>
        </DrawerComp>
    )
}

export default PaymentFilters