import { faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons';
import { Fragment, useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { showRecharges } from '../../../api/Recharges';
import ButtonIcon from '../../layouts/ButtonIcon';
import FormIcon from '../../layouts/FormIcon';
import ModalComp from '../../layouts/Modal'

const RechargeDetails = (props) => {
    const { onClose, movement, headers } = props;
    const [detail, setDetail] = useState({});

    useEffect(() => {
        getDetails();
    }, []);

    const getDetails = async () => {
        let response = await showRecharges({ headers, recharge_id: movement.id });
        if (response) setDetail(response);
    }
    return (
        <ModalComp
            onClose={onClose}
            title='Detalles de la recarga'
            size='lg'
            body={<Fragment>
                 <Row className='mb-3'>
                    <FormIcon title='Folio' value={detail?.folio} />
                    <FormIcon title='Folio interno' value={detail?.id} />
                    <FormIcon title='Número de autorización' value={detail?.autcarrier} />
                    <FormIcon title='Estado' value={detail?.isDone ? 'Hecho' : 'Fallido'} />
                </Row>
                <Row className='mb-3'>
                    <FormIcon title='Cajero' value={detail?.checker?.first_name + ' ' + detail?.checker?.last_name} />
                    <FormIcon title='Recarga' value={detail?.product?.name} />
                </Row>
                <Row className='mb-3'>
                    <FormIcon title='Número teléfonico' value={detail?.phone} />
                    <FormIcon title='Cantidad' value={detail?.amount} icon='$' />
                </Row>
                <Row className='mb-3'>
                    <FormIcon title='Creado en' value={detail?.created_at} />
                    <FormIcon title='Actualizado en' value={detail?.updated_at} />
                </Row>
            </Fragment>}
            footer={<Fragment>
                <ButtonIcon icon={faAngleDoubleLeft} name='Cerrar' variant='outline-secondary' onClick={onClose} />
            </Fragment>}
        />
    )
}

export default RechargeDetails