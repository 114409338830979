import { Fragment } from "react"
import ChangePassword from "./ChangePassword"
import TwoFactor from "./TwoFactor"

const Security = () => {
    return (
        <Fragment>
            <ChangePassword />
            <TwoFactor />
        </Fragment>
    )
}

export default Security