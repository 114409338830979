import { Text } from '@chakra-ui/react';
import { faArrowLeft, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import React, { Fragment } from 'react';
import ButtonIcon from '../layouts/ButtonIcon';
import ModalComp from '../layouts/Modal';

const TwoFactorModal = (props) => {
    const { onClose, type, confirm } = props;
    return (
        <ModalComp
            onClose={onClose}
            title='Autenticación en dos pasos'
            body={<Fragment>
                {
                    type === 'enable' ?
                        <Fragment>
                            {/* Mensaje para habilitar Autentificación en dos pasos */}
                            <Text fontSize='lg' fontWeight='bold'>
                                ¿Está seguro que desea activar la autentificación en dos pasos?
                            </Text>
                            <Text>
                                A partir de este momento el proceso de autentificación será más seguro, ya que se enviará un correo electrónico con un código de verificación que nos permitirá validar su identidad con mayor seguridad.
                            </Text>
                        </Fragment> :
                        <Fragment>
                            {/* Mensaje para deshabilitar Autentificación en dos pasos */}
                            <Text fontSize='lg' fontWeight='bold'>
                                ¿Está seguro que desea desactivar la autentificación en dos pasos?
                            </Text>
                            <Text>
                                A partir de este momento el proceso de autentificación será sólo a través de su correo electrónico y contraseña, lo que brindará menos seguridad ya que, quedará susceptible al robo de sus credenciales.
                            </Text>
                        </Fragment>
                }
            </Fragment>}
            footer={<Fragment>
                <ButtonIcon name='Cerrar' icon={faArrowLeft} onClick={onClose} variant='outline-secondary' />
                <ButtonIcon
                    name={type === 'enable' ? 'Activar':'Desactivar'}
                    icon={type === 'enable' ? faCheck : faTimes} onClick={confirm}
                    variant={type === 'enable' ? 'outline-success' : 'outline-danger'}
                />
            </Fragment>}
        />
    );
};

export default TwoFactorModal;
