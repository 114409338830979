import { Heading } from '@chakra-ui/react'
import { faChartArea, faEdit, faEye } from '@fortawesome/free-solid-svg-icons';
import { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { indexTechnicalAgents } from '../../api/TechnicalAgents';
import FlexButton from '../layouts/FlexButton';
import PaginationComp from '../layouts/Pagination';
import TableComp from '../layouts/TableComp'
import Add from './TechnicalAgents/Add';
import Details from './TechnicalAgents/Details';
import Download from './TechnicalAgents/Download';
import Edit from './TechnicalAgents/Edit';

const TechnicalAgents = () => {
	const login = useSelector(state => state.login);
	const headers = { Authorization: `Bearer ${login.token}` };
	const user_id = login.user.id;

	const [agentTechnicals, setAgentTechnicals] = useState([]);
	const [agentSel, setAgentSel] = useState({});
	const [loading, setLoading] = useState(false);
	const [modalEdit, setModalEdit] = useState(false);
	const [modalView, setModalView] = useState(false);
	const [modalAdd, setModalAdd] = useState(false);
	const [modalReport, setModalReport] = useState(false);
	const [totalItems, setTotalItems] = useState(0);
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);

	useEffect(() => {
		getTechnicalAgents();
	}, []);

	const getTechnicalAgents = async () => {
		setLoading(true);
		let response = await indexTechnicalAgents({ headers, user_id, page, pageSize });
		setAgentTechnicals(response.data);
		setTotalItems(response.totalItems);
		setLoading(false);
	}

	const openOrCloseEdit = () => setModalEdit(!modalEdit);
	const openOrCloseView = () => setModalView(!modalView);
	const openOrCloseAdd = () => setModalAdd(!modalAdd);
	const openOrCloseReport = () => setModalReport(!modalReport);

	// Editar datos de agente técnico
	const editAgent = (item) => {
		setAgentSel(item);
		openOrCloseEdit();
	}
	// Ver detalles de agente
	const viewAgent = (item) => {
		setAgentSel(item);
		openOrCloseView();
	}
	const modalDownload = (item) => {
		setAgentSel(item);
		openOrCloseReport();
	}
	const changePage = (page, pageSize) => {
		setPage(page);
		setPageSize(pageSize);
		getTechnicalAgents();
	}
	return (
		<Fragment>
			<Heading className='mb-4'>Agentes técnicos</Heading>

			<TableComp
				headers={['#', 'Nombre', 'Correo electrónico', 'Creado en', 'Acciones']}
				keys={['#', 'full_name', 'email', 'created_at']}
				body={agentTechnicals}
				loading={loading}
				actions={[
					{ icon: faEdit, tooltip: 'Editar datos de Agente técnico', handleClick: editAgent, variant: 'outline-success' },
					{ icon: faEye, tooltip: 'Ver detalles de Agente técnico', handleClick: viewAgent, variant: 'outline-primary' },
					{ icon: faChartArea, tooltip: 'Descargar reporte de agente técnico', handleClick: modalDownload, variant: 'outline-success' },
				]}
			/>
			{/* Componente de paginación */}
			<PaginationComp totalItems={totalItems} current={page} onChange={changePage} />

			{/* Botón para abrir modal ADD */}
			<FlexButton style={{ bottom: 40, right: 40 }} onClick={openOrCloseAdd} />
			{/* Modal para agregar agente ténico */}
			{
				modalAdd && <Add
					onClose={openOrCloseAdd}
					headers={headers}
					update={getTechnicalAgents}
				/>
			}
			{/* Modal para ver detalles de agente técnico */}
			{
				modalView && <Details
					onClose={openOrCloseView}
					headers={headers}
					agent={agentSel}
				/>
			}
			{/* Modal para editar datos de agente técnico */}
			{
				modalEdit && <Edit
					onClose={openOrCloseEdit}
					headers={headers}
					agent={agentSel}
					update={getTechnicalAgents}
				/>
			}
			{/* Modal para generar reporte de agente técnico */}
			{
				modalReport && <Download
					onClose={openOrCloseReport}
					headers={headers}
					agent={agentSel}
				/>
			}
		</Fragment>
	)
}

export default TechnicalAgents