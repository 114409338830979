import { Box, Center, Heading, Image, Text } from '@chakra-ui/react';

const Item = (props) => {
    const { logo, plan } = props;
    return (
        <Box maxW='sm' minH={'60'} borderWidth='1px' borderRadius='lg' overflow='hidden' style={{ backgroundColor: '#FFF' }} className='my-card' textAlign={'center'}>
            <Center>
                <Image src={logo} maxHeight={40} minHeight={40} />
            </Center>
            {
                plan &&
                <Box className='mb-3'>
                    <Heading size={'md'}>{plan?.name}</Heading>
                    <Text>{plan?.description}</Text>
                </Box>
            }
        </Box>
    )
}

export default Item