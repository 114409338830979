import { useToast } from '@chakra-ui/react';
import { faAngleDoubleLeft, faSave } from '@fortawesome/free-solid-svg-icons';
import { Fragment, useState } from 'react';
import { Row } from 'react-bootstrap';
import { updateTechnicalAgents } from '../../../api/TechnicalAgents';
import setToast from '../../../libs/Toast';
import ButtonIcon from '../../layouts/ButtonIcon';
import FormIcon from '../../layouts/FormIcon';
import ModalComp from '../../layouts/Modal'

const Edit = (props) => {
    const { onClose, agent, headers, update } = props;
    const [data, setData] = useState({});
    const toast = useToast();

    const handleChange = (e) => {
        let { value, name } = e.target;
        setData({ ...data, [name]: value });
    }

    const updateAgent = async () => {
        let response = await updateTechnicalAgents({ headers, data, agent_id: agent.id });
        if (response) {
            setToast({
                toast, title: '¡Operación exitosa!', status: 'success',
                description: 'Los datos del agente técnico han sido actualizados con éxito.'
            });
            update();
            onClose();
        }else{
            setToast({
                toast, title: '¡Operación fallida!', status: 'warning',
                description: 'Ha ocurrido un error al tratar de actualizar los datos del agente técnico.'
            });
        }
    }
    return (
        <ModalComp
            title='Editar agente técnico'
            size='md'
            onClose={onClose}
            body={<Fragment>
                <Row className='mb-3'>
                    <FormIcon title='Nombre(s)' name='first_name' defaultValue={agent?.first_name} value={data?.first_name} handleChange={handleChange} />
                </Row>
                <Row className='mb-3'>
                    <FormIcon title='Apellido(s)' name='last_name' defaultValue={agent?.last_name} value={data?.last_name} handleChange={handleChange} />
                </Row>
                <Row className='mb-3'>
                    <FormIcon title='Correo electrónico' name='email' defaultValue={agent?.email} value={data?.email} handleChange={handleChange} />
                </Row>
                <Row className='mb-3'>
                    <FormIcon title='Contraseña' name='password' defaultValue={agent?.password} value={data?.password} type='password' handleChange={handleChange} />
                </Row>
                {/* <Row className='mb-3'>
                    <FormIcon title='Confirmar contraseña' name='passsword_confirm' value={data?.passsword_confirm} type='password' />
                </Row> */}
            </Fragment>}
            footer={<Fragment>
                <ButtonIcon name='Cerrar' icon={faAngleDoubleLeft} onClick={onClose} variant='outline-secondary' />
                <ButtonIcon name='Actualizar datos' icon={faSave} onClick={updateAgent} variant='outline-success' />
            </Fragment>}
        />
    )
}

export default Edit