import { Center, HStack, PinInput, PinInputField, Text, useToast } from "@chakra-ui/react";
import { faPaperPlane, faSpellCheck } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { resendCode, verify } from "../../api/Authenticate";
import { showLog } from "../../libs/Logs";
import setToast from "../../libs/Toast";
import ButtonIcon from "../layouts/ButtonIcon";

const VerificationCode = (props) => {
    const { email, goHome } = props;
    const [code, setCode] = useState('');
    const [resend, setResend] = useState(false);
    const [seconds, setSeconds] = useState(59);
    const [minutes, setMinutes] = useState(1);
    const [loading, setLoading] = useState(false);

    const stylePin = { backgroundColor: '#FFF' };
    const toast = useToast();

    useEffect(() => {
        if (seconds === 0 && minutes > 0) {
            setSeconds(59);
            setMinutes(minutes => minutes - 1);
        }
        if (seconds === 0 && minutes === 0)
            setResend(true);
        showLog({ message: "Second: ", obj: seconds });
    }, [seconds]);

    useEffect(() => {
        const interval = setInterval(() => {
            setSeconds(seconds => seconds - 1);
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    const isValidData = () => {
        return code.length === 6 ? true : false;
    }
    const verifyCode = async () => {
        setLoading(true);
        if (isValidData()) {
            let response = await verify({ email, code });
            if (response.res) { // Código validado correctamente
                // Abrir sesión
                goHome(response.data);
                setToast({
                    toast, title: '¡Autenticación correcta!', status: 'success',
                    description: 'Ha iniciado sesión de manera satisfactoria'
                });
            } else {
                setToast({
                    toast, title: '¡Autenticación incorrecta!', status: 'warning',
                    description: 'El código proporcionado no coincide con el último código que fue enviado a su dirección de correo electrónico. Verifique por favor.'
                });
            }
        } else {
            setToast({  // Mensaje de retroalimentación: warning
                title: '¡Código de validación incompleto!', toast, status: 'warning',
                description: 'El código debe tener una longitud de 6 dígitos.'
            });
        }
        setLoading(false);
    }

    const resendCodeNow = async () => {
        let response = await resendCode({ email });
        if (response.res) {
            setToast({
                toast, title: '¡Código reenviado!', status: 'success',
                description: response.msg
            });
            setResend(false)
            setMinutes(5)
            setSeconds(59);
        } else {
            setToast({
                toast, title: '¡Error al reenviar!', status: 'warning',
                description: 'Ha ocurrido un error al tratar de reenviar el código de confirmación a la dirección ' + email + '.'
            });
        }
    }

    const handleChange = (value) => { setCode(value) }

    return (
        <Fragment>
            <Row className='ms-3 me-3 mb-5'>
                <Text fontSize='lg' color='whiteAlpha.800'>
                    <Text color='black'>
                        Un código de verificación de 6 dígitos fue enviado a la dirección de correo
                    </Text>
                    <Text color='teal.500'>{email}</Text>
                </Text>
                <Center>
                    <HStack className="mt-5">
                        <PinInput
                            size='lg'
                            type='alphanumeric'
                            onChange={handleChange}
                        >
                            <PinInputField style={stylePin} />
                            <PinInputField style={stylePin} />
                            <PinInputField style={stylePin} />
                            <PinInputField style={stylePin} />
                            <PinInputField style={stylePin} />
                            <PinInputField style={stylePin} />
                        </PinInput>
                    </HStack>
                </Center>
                <div className="d-grid gap-2 mt-5">
                    <ButtonIcon
                        loading={loading}
                        size='md'
                        variant='primary'
                        icon={faSpellCheck}
                        name='Verificar código'
                        onClick={verifyCode}
                        disabled={!isValidData()}
                    />
                </div>
                <div className="d-grid gap-2 mt-3">
                    <ButtonIcon size='md' variant='outline-secondary' icon={faPaperPlane} name={resend ? 'Reenviar código' : `Reenviar código ${moment(`${minutes}:${seconds}`, 'mm:ss').format('mm:ss')}`} onClick={resendCodeNow} disabled={!resend} />
                </div>
            </Row>
        </Fragment>
    );
};

export default VerificationCode;
