import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import PrivateRoute from "../libs/Middleware";
import Balances from "./1.Agent/Balances";
import Desposit from "./1.Agent/Deposit";
import Pending from "./1.Agent/Pending";
import TechnicalAgents from "./1.Agent/TechnicalAgents";
import Balance from "./2.TechnicalAgent/Balance";
import Cajeros from "./2.TechnicalAgent/Cajeros";
import Comprobantes from "./2.TechnicalAgent/Comprobantes";
import Movimientos from "./2.TechnicalAgent/Movimientos";
import Recharges from "./3.Checker/Recharges";
import ServicesPayment from "./3.Checker/ServicesPayment";
import ConfigComp from "./Config/Index";
import Home from "./Home/Index";
import MainLayout from "./layouts/MainLayout";
import RMainLayout from "./layouts/RMainLayout";
import PageNotFound from "./PageNotFound/Index";
import PermissionDenied from "./PermissionDenied/Index";

const App = () => {

    return (
        <Router>
            {/* <MainLayout> */}
            <RMainLayout>
                {/* Switch que redirecciona al componente de la ruta establecida */}
                <Switch>
                    {/* 
                        --- N O T A S --- 
                        PrivateRoute: Es un middleware que ayuda a controlar el acceso en base al nivel de acceso que tiene cada usuario.
                        Route: Rutas que no necesitan protección.
                    */}
                    <Route exact path='/' component={Home} />

                    {/* Rutas globales */}
                    <Route exact path='/movimientos' component={Movimientos} />
                    <Route exact path='/config' component={ConfigComp} />

                    {/* Agentes técnicos */}
                    <Route exact path='/cajeros' component={Cajeros} />
                    <Route exact path='/comprobantes' component={Comprobantes} />
                    <Route exact path='/saldo-agente-tecnico' component={Balance} />

                    {/* Agentes */}
                    <Route exact path='/agentes-tecnicos' component={TechnicalAgents} />
                    <Route exact path='/depositos' component={Desposit} />
                    <Route exact path='/saldo' component={Balances} />
                    <Route exact path='/pendientes' component={Pending} />

                    {/* Cajeros */}
                    <Route exact path='/recargas' component={Recharges} />
                    <Route exact path='/pago-servicios' component={ServicesPayment} />

                    {/* Vista a la que se redirecciona cuando no se encuentra la ruta */}
                    <Route exact component={PageNotFound} />

                    {/* Vista que se muestra cuando no hay permiso para acceder a la ruta deseada */}
                    <Route exect path='/permission-denied' component={PermissionDenied} />
                </Switch>
            </RMainLayout>
            {/* </MainLayout> */}
        </Router>
    );
}

export default App;