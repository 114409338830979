import { faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { Fragment, useEffect } from 'react';
import { showTechnicalAgents } from '../../../api/TechnicalAgents';
import ButtonIcon from '../../layouts/ButtonIcon';
import ModalComp from '../../layouts/Modal'
import TableComp from '../../layouts/TableComp';

const Details = (props) => {
    const { onClose, agent, headers } = props;
    const [data, setData] = useState({});

    useEffect(() => {
        getDetails();
    }, []);

    const getDetails = async () => {
        let response = await showTechnicalAgents({ headers, agent_technical_id: agent.id });
        if (response) setData(response);
    }

    return (
        <ModalComp
            title='Detalles de agente técnico'
            size='lg'
            onClose={onClose}
            body={<Fragment>
                <TableComp
                    headers={['#', 'Carrier', 'Saldo restante', '']}
                    keys={['#', 'carrier', 'balance_remain']}
                    body={data?.balances}
                    currency={[{ index: 2 }]}
                />
            </Fragment>}
            footer={<Fragment>
                <ButtonIcon name='Cerrar' icon={faAngleDoubleLeft} onClick={onClose} variant='outline-secondary' />
            </Fragment>}
        />
    )
}

export default Details