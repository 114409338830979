import { Heading } from '@chakra-ui/react'
import { Fragment, useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import movistar_logo from '../../images/movistar.png';
import telcel_logo from '../../images/telcel.png';
import att_logo from '../../images/att.png';
import services_logo from '../../images/services.jpg';
import Item from './Index/Item'
import { indexBalances } from '../../api/Balances';
import { useSelector } from 'react-redux';

const Balance = () => {
    const login = useSelector(state => state.login);
    const headers = { Authorization: `Bearer ${login.token}` };

    const [balances, setBalances] = useState([]);

    useEffect(() => {
        getBalances();
    }, []);

    const getBalances = async () => {
        let response = await indexBalances({ headers });
        setBalances(response.data);
    }
    return (
        <Fragment>
            <Heading>Monederos electrónicos</Heading>
            <Container fluid>
                <Row className='mt-5'>
                    {
                        balances.map((balance, index) =>
                            <Col key={index} sm={12} md={3} className='mt-3'>
                                <Item balance={balance} />
                            </Col>
                        )
                    }
                </Row>
                {/* <Row className='mt-5'>
                    <Col sm={12} md={3} className='mt-3'>
                        <Item logo={telcel_logo} amount={500} carrier='TELCER' />
                    </Col>
                    <Col sm={12} md={3} className='mt-3'>
                        <Item logo={movistar_logo} amount={1000} carrier='MOVISTAR' />
                    </Col>
                    <Col sm={12} md={3} className='mt-3'>
                        <Item logo={att_logo} amount={200} carrier='AT&T' />
                    </Col>
                    <Col sm={12} md={3} className='mt-3'>
                        <Item logo={services_logo} amount={200} carrier='SERVICIOS' />
                    </Col>
                </Row> */}
            </Container>
        </Fragment>
    )
}

export default Balance