import FetcherDrissly from "../libs/FetcherDrissly";

const STATUS_OK = 200;

export const getDrisslyBalance = async ({ headers, id_carrier, region }) => {
    let response = false;
    try {
        let fetch = await FetcherDrissly({
            method: 'POST',
            url: '/get_balance',
            headers,
            data: {
                region,
                id_carrier
            }
        });
        console.log("get_balance: ", fetch);
        if (fetch.status === STATUS_OK) {
            if (fetch.data.code === STATUS_OK)
                response = fetch.data.carriers[0]
        }
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}

export const transaction = async ({ headers, data }) => {
    let response = false;
    try {
        let fetch = await FetcherDrissly({
            method: 'POST',
            url: 'transaction',
            headers,
            data
        });
        console.log('Transaction service: ', fetch);
        if (fetch.status === STATUS_OK)
            response = fetch.data
    } catch (error) {
        response = false;
    }finally{
        return response;
    }
}

export const transaction_service = async ({ headers, data }) => {
    let response = false;
    try {
        let fetch = await FetcherDrissly({
            method: 'POST',
            url: 'transaction_service',
            headers,
            data
        });
        console.log('Transaction service: ', fetch);
        if (fetch.status === STATUS_OK)
            response = fetch.data
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}