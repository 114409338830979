import { Heading } from '@chakra-ui/react'
import { Fragment } from 'react'

const Pending = () => {
  return (
    <Fragment>
        <Heading>Pendientes</Heading>
    </Fragment>
  )
}

export default Pending