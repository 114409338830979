import React, { Fragment, useState } from 'react'
import { UploadOutlined, UserOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faCashRegister, faCogs, faCoins, faCommentDollar, faFileInvoiceDollar, faHistory, faHome, faTicketAlt, faUser, faUsers } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../api/Authenticate';
import { CLOSE_SESSION } from '../../redux/actions/main';
import { showLog } from '../../libs/Logs';
const { Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

const RMainLayout = (props) => {
    const { children } = props;
    const login = useSelector(state => state.login);
    const user = login.user;
    const headers = { Authorization: `Bearer ${login.token}` };
    const dispatch = useDispatch();
    const role = useSelector(state => state.login.role);
    const [marginLeft, setMarginLeft] = useState('12em');

    const closeSession = async () => {
        logout({ headers });
        dispatch({ type: CLOSE_SESSION });
    }

    return (
        <Layout>
            <Sider
                breakpoint="lg"
                collapsedWidth="0"
                onBreakpoint={(broken) => {
                    showLog({ message: 'Broken: ', obj: broken });
                }}
                onCollapse={(collapsed, type) => {
                    showLog({ message: 'onCollapse: ', obj: collapsed });
                    if (collapsed) setMarginLeft('0em');
                    else setMarginLeft('12em');
                }}
                style={{ position: "fixed", height: "100vh", zIndex: 2 }}
            >
                {/* <div className="logo" /> */}
                <Menu
                    theme="dark"
                    mode="inline"
                    defaultSelectedKeys={['4']}
                >
                    <Menu.Item key="1" icon={<FontAwesomeIcon icon={faHome} />}>
                        <Link to="/" /> FastPay
                    </Menu.Item>

                    {/* Rol: Agent */}
                    {
                        role.name === 'AGENTE' && <Fragment>

                            <Menu.Item icon={<FontAwesomeIcon icon={faUsers} />}>
                                <Link to='/agentes-tecnicos' /> Agentes técnicos
                            </Menu.Item>
                            <Menu.Item icon={<FontAwesomeIcon icon={faFileInvoiceDollar} />}>
                                <Link to='/depositos' /> Depósitos
                            </Menu.Item>
                            <Menu.Item icon={<FontAwesomeIcon icon={faCoins} />}>
                                <Link to='/saldo' /> Saldo
                            </Menu.Item>
                            <Menu.Item icon={<FontAwesomeIcon icon={faCoins} />}>
                                <Link to='/movimientos' /> Movimientos
                            </Menu.Item>
                            {/* <Menu.Item icon={<FontAwesomeIcon icon={faSpinner} />}>
                                <Link to='/pendientes' /> Pendientes
                            </Menu.Item> */}

                        </Fragment>
                    }
                    {/* Rol: Technical Agent */}
                    {
                        role.name === 'AGENTE_TECNICO' && <Fragment>

                            <Menu.Item icon={<FontAwesomeIcon icon={faCashRegister} />}>
                                <Link to='/cajeros' /> Cajeros
                            </Menu.Item>
                            <Menu.Item icon={<FontAwesomeIcon icon={faTicketAlt} />}>
                                <Link to='/comprobantes' /> Comprobantes
                            </Menu.Item>
                            <Menu.Item icon={<FontAwesomeIcon icon={faCoins} />}>
                                <Link to='/saldo-agente-tecnico' /> Saldo
                            </Menu.Item>
                            <Menu.Item icon={<FontAwesomeIcon icon={faHistory} />}>
                                <Link to='/movimientos' /> Movimientos
                            </Menu.Item>
                        </Fragment>
                    }
                    {/* Rol: Checker */}
                    {
                        role.name === 'CAJERO' && <Fragment>

                            <Menu.Item icon={<FontAwesomeIcon icon={faCommentDollar} />}>
                                <Link to='/recargas' /> Recargas
                            </Menu.Item>
                            <Menu.Item icon={<FontAwesomeIcon icon={faFileInvoiceDollar} />}>
                                <Link to='/pago-servicios' /> Pago de servicios
                            </Menu.Item>
                            <Menu.Item icon={<FontAwesomeIcon icon={faHistory} />}>
                                <Link to='/movimientos' /> Movimientos
                            </Menu.Item>
                        </Fragment>
                    }
                    <Menu.Item icon={<FontAwesomeIcon icon={faCogs} />}>
                        <Link to='/config' /> Configuración
                    </Menu.Item>
                </Menu>
            </Sider>
            <Layout>
                <Menu theme="dark" mode="horizontal" style={{ justifyContent: 'flex-end', position: "fixed", width: "100%", zIndex: 1 }}>
                    <SubMenu key="notifications" icon={<FontAwesomeIcon icon={faBell} />} title=" Notificaciones">
                        <Menu.Item key="setting:1">No hay notificaciones</Menu.Item>
                    </SubMenu>
                    <SubMenu key="user" icon={<FontAwesomeIcon icon={faUser} />} title={" " + user.email}>
                        <Menu.Item key="config"><Link to={'/config'} /> Configuración de perfil</Menu.Item>
                        <Menu.Item key="logout" onClick={closeSession}> Cerrar sesión</Menu.Item>
                    </SubMenu>
                </Menu>
                <Content
                    style={{
                        margin: '24px 16px 0',
                    }}
                >
                    <div
                        className="site-layout-background"
                        style={{
                            paddingLeft: 40,
                            height: '100%',
                            minHeight: "84vh",
                            marginTop: '6vh',
                            marginLeft
                        }}
                    >
                        {children}
                    </div>
                </Content>
                <Footer
                    style={{
                        textAlign: 'center',
                    }}
                >
                    FastPay By GRUPO CTI
                </Footer>
            </Layout>
        </Layout>
    )
}

export default RMainLayout