import { Heading } from '@chakra-ui/react'
import { faReceipt } from '@fortawesome/free-solid-svg-icons'
import { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { indexServices } from '../../api/Services'
import PaginationComp from '../layouts/Pagination'
import TableComp from '../layouts/TableComp'
import ModalPay from './PaymentServices/ModalPay'
import Search from './PaymentServices/Search'

const ServicesPayment = () => {
	const login = useSelector(state => state.login);
	const headers = { Authorization: `Bearer ${login.token}` };
	const user_id = login.user.id;

	const [payments, setPayments] = useState([]);
	const [paymentSel, setPaymentSel] = useState({});
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [name, setName] = useState('');
	const [totalItems, setTotalItems] = useState(0);
	const [loading, setLoading] = useState(true);
	const [modalPay, setModalPay] = useState(false);

	useEffect(() => {
		getPayments();
	}, [page, pageSize]);

	const getPayments = async () => {
		setLoading(true);
		let response = await indexServices({ headers, page, pageSize, name });
		setTotalItems(response.totalItems);
		setPayments(response.data);
		setLoading(false);
	}
	const changePage = (page, pageSize) => {
		setPage(page);
		setPageSize(pageSize);
	}

	const openOrClosePay = () => setModalPay(!modalPay);

	const payService = (item) => {
		setPaymentSel(item);
		openOrClosePay();
	}

	return (
		<Fragment>
			<Heading className='mb-3'>Pago de servicios</Heading>
			<Search
				name={name}
				setName={setName}
				getPayments={getPayments}
			/>
			<TableComp
				headers={['#', 'Folio', 'Servicio', 'Descripción', 'Creado en', 'Acciones']}
				keys={['#', 'id_product', 'name', 'description', 'created_at']}
				body={payments}
				loading={loading}
				loadingItems={pageSize}
				page={page}
				actions={[
					{ icon: faReceipt, variant: 'outline-success', tooltip: 'Pagar servicio', handleClick: payService }
				]}
			/>
			<PaginationComp totalItems={totalItems} current={page} onChange={changePage} />
			{
				modalPay && <ModalPay
					onClose={openOrClosePay}
					headers={headers}
					payment={paymentSel}
					user_id={user_id}
				/>
			}
		</Fragment>
	)
}

export default ServicesPayment