import { Input, Text, useToast } from '@chakra-ui/react';
import { faAngleDoubleLeft, faChartArea } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { useEffect } from 'react';
import { useState } from 'react';
import { Fragment } from 'react';
import { Row } from 'react-bootstrap';
import { now } from 'underscore';
import downloadFile from '../../../libs/Download';
import { showLog } from '../../../libs/Logs';
import ButtonIcon from '../../layouts/ButtonIcon';
import FormIcon from '../../layouts/FormIcon';
import ModalComp from '../../layouts/Modal'

const Download = (props) => {
    const { onClose, agent, headers } = props;
    const [data, setData] = useState({});
    const [active, setActive] = useState(false);
    const toast = useToast();

    useEffect(() => {
        // showLog({message: 'Fecha: ', obj: moment().format('YYYY-MM-DD')});
        if (data?.start_date && data?.end_date)
            setActive(true);
        else
            setActive(false);
    }, [data]);

    const getDownload = async () => {
        const { start_date, end_date } = data;
        downloadFile({
            headers,
            toast,
            method: 'GET',
            url: `/reports`,
            params: {
                agent_technical_id: agent.id,
                start_date,
                end_date
            },
            name: `Estado de cuenta - ${agent.first_name} ${agent.last_name}`,
            type: 'application/json',
        });
    }
    const handleChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    }
    return (
        <ModalComp
            title='Descargar reporte'
            size='md'
            onClose={onClose}
            body={<Fragment>
                <Text>
                    Para continuar con esta operación es necesario que indique el rango de fechas para realizar la búsqueda.
                </Text>
                <Row className='mt-5 mb-5'>
                    <FormIcon
                        title='Fecha inicial'
                        type='date'
                        name='start_date'
                        value={data?.start_date}
                        handleChange={handleChange}
                        max={moment().subtract(3, 'days').format('YYYY-MM-DD')}
                    />
                    <FormIcon
                        title='Fecha final'
                        type='date'
                        name='end_date'
                        value={data?.end_date}
                        handleChange={handleChange}
                        max={moment().format('YYYY-MM-DD')}
                    />
                </Row>
            </Fragment>}
            footer={<Fragment>
                <ButtonIcon name='Cerrar' icon={faAngleDoubleLeft} onClick={onClose} variant='outline-secondary' />
                <ButtonIcon name='Descargar reporte' icon={faChartArea} onClick={getDownload} variant='outline-success' disabled={!active} />
            </Fragment>}
        />
    )
}

export default Download