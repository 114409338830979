import { Heading } from '@chakra-ui/react'
import { Col, Row } from 'antd'
import { Fragment } from 'react'
import { useSelector } from 'react-redux'
import TabsComp from '../layouts/Tabs'
import RechargesTable from './Movimientos/Recharges'
import ServicesTable from './Movimientos/Services'

const Movimientos = () => {
    const login = useSelector(state => state.login);
    const headers = { Authorization: `Bearer ${login.token}` };

    return (
        <Fragment>
            <Heading className='mb-3'>Movimientos</Heading>
            <TabsComp
                tablist={['Recargas', 'Pago de servicios']}
                tabpanels={[
                    <RechargesTable
                        headers={headers}
                    />,
                    <ServicesTable
                        headers={headers}
                    />
                ]}
            />
        </Fragment>
    )
}

export default Movimientos