import { faAngleDoubleLeft, faSave } from '@fortawesome/free-solid-svg-icons';
import { Fragment, useEffect, useState } from 'react';
import { useToast } from '@chakra-ui/react';
import { Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { transaction_service } from '../../../api/Drissly';
import { storePaymentServices } from '../../../api/PaymentServices';
import setToast from '../../../libs/Toast';
import ButtonIcon from '../../layouts/ButtonIcon';
import FormIcon from '../../layouts/FormIcon';
import ModalComp from '../../layouts/Modal'
import SkeletonStack from '../../layouts/Skeletons/Stack';
import { indexBalances } from '../../../api/Balances';

const _ = require('underscore');

const data_struct = [
    { key: 'phone', name: 'Número telefónico' },
    { key: 'amount', name: 'Monto' },
    { key: 'reference', name: 'Número de referencia' }
];

const ModalPay = (props) => {
    const { onClose, headers, payment, user_id } = props;
    const token_drissly = useSelector(state => state.login.token_drissly);
    const headers_d = { Authorization: `Bearer ${token_drissly}` };

    const toast = useToast();
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [balances, setBalances] = useState({});

    useEffect(() => {
        getBalances();
        const { amount } = payment;
        if (amount !== 0) setData({ amount });
    }, []);

    const getBalances = async () => {
        let response = await indexBalances({ headers });
        setBalances(response.data);

    }
    const handleChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    }
    // Validar que los campos son correctos
    const validateFields = () => {
        let response = true;
        for (let index = 0; index < data_struct.length; index++) {
            const { key, name } = data_struct[index];
            if (!data.hasOwnProperty(key)) {
                response = false;
                setToast({
                    toast, title: '¡Formulario incompleto!', status: 'warning',
                    description: `El campo que corresponde a ${name} no puede estar vacío.`
                });
                break;
            }
        }
        return response;
    }
    // Validar que el saldo restante es suficiente
    const validateBalance = () => {
        let response = false;
        let pos = _.findIndex(balances, { carrier: 'SERVICIOS' });
        if (pos !== -1) {
            let s_balance = balances[pos];
            if (s_balance.balance_remain >= data?.amount) response = true
            else
                setToast({
                    toast, title: '¡Saldo insuficiente!', status: 'warning',
                    description: `El saldo disponible en su monedero es de $${s_balance.balance_remain} por lo que no se puede efectuar la operación.`
                });
        }
        return response;
    }
    const payService = async () => {
        if (validateFields() && validateBalance()) {
            setLoading(true);
            let response = await transaction_service({
                headers: headers_d,
                data: {
                    ...data,
                    id_product: payment.id_product
                }
            });
            let data_success = {};
            if (response) {
                let { folio, code, autcarrier } = response;
                if (code === 200)
                    data_success = {
                        isDone: true,
                        folio,
                        autcarrier,
                    }
                setToast({
                    toast, title: '¡Operación exitosa!', status: 'success',
                    description: `El pago del servicio ${payment.name} por la cantidad de $${data?.amount} se completo satisfactoriamente.`
                });
            } else {
                setToast({
                    toast, title: '¡Operación fallida!', status: 'warning',
                    description: `El pago del servicio ${payment.name} por la cantidad de $${data?.amount} no se pudo completar. Comuníquese con el administrador.`
                });
            }
            await storePaymentServices({
                headers,
                data: {
                    ...data,
                    ...data_success,
                    service_id: payment.id,
                    checker_id: user_id,
                }
            });
            setLoading(false);
            onClose();
        }
    }
    return (
        <ModalComp
            onClose={onClose}
            title={`Pagar ${payment.name}`}
            size={'md'}
            body={<Fragment>
                {
                    loading ? <SkeletonStack rows={4} /> : <Fragment>
                        <Row className='mb-3'>
                            <FormIcon title='Número telefónico' type='tel' name='phone' value={data?.phone} handleChange={handleChange} />
                        </Row>
                        <Row className='mb-3'>
                            <FormIcon title='Monto' type='number' icon='$' name='amount' value={data?.amount} handleChange={handleChange} />
                        </Row>
                        <Row className='mb-3'>
                            <FormIcon title='Número de referencia' name='reference' value={data?.reference} handleChange={handleChange} />
                        </Row>
                        <Row className='mb-3'>
                            <FormIcon title='Datos adicionales [opcional]' name='aditional' value={data?.aditional} handleChange={handleChange} />
                        </Row>
                    </Fragment>
                }
            </Fragment>}
            footer={<Fragment>
                <ButtonIcon name='Cerrar' icon={faAngleDoubleLeft} onClick={onClose} variant='outline-secondary' disabled={loading} />
                <ButtonIcon name='Pagar' icon={faSave} onClick={payService} variant='outline-success' disabled={loading} />
            </Fragment>}
        />
    )
}

export default ModalPay